.heroSection {
    height: 50rem;
    background:
        linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.314)),
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.166)),
        url('../../Theme/Product/SubcategoryImages/laundry-22.webp');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    color: white;
    /* padding: 2rem; */
}

.textWrapper {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    height: 100%;
    /* background-color: aqua; */
    justify-content: center;
}

.mainTitleText {
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
}

.sectionTitleText {
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
}

.topTitleText {
    font-size: var(--text-size-01);
    /* text-transform: uppercase; */
    /* font-family: var(--slim-title-font-family); */
}

.subtitleText {
    font-size: var(--text-size-02);
    /* font-family: var(--slim-title-font-family); */
}

.buttonBox {
    margin-top: 1rem;
}

.buttonBoxWrapper {
    display: flex;
    /* flex-direction: row; */
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.introTextBox {
    padding: 4rem 0;
    /* padding: 4rem 2rem; */
    font-family: var(--slim-title-font-family);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.featureSection {
    display: flex;
    flex-direction: column;
    /* margin-top: 5rem; */
    row-gap: 2rem;
    margin-bottom: 4rem;
}

.card {
    background-color: var(--color-gray-06);
    border: 1px solid var(--color-gray-05);
    padding: 2rem;
    border-radius: 16px;

    display: grid;
    grid-template-columns: 1fr .2fr;
    align-items: stretch;
}

.cardTextBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: var(--slim-title-font-family);
}

.cardTitleText {
    font-size: var(--text-size-03);
    /* font-size: var(--text-size-2); */
}

.bodyText {
    font-size: var(--text-size-01);
    color: var(--color-gray-03);
    /* margin-top: 1rem; */
}
.purchaseProBodyText{
    font-size: var(--text-size-01);
    color: var(--color-gray-03);
}

.cardImageBox {
    height: 10rem;
    width: 100%;
    display: inline-flex;

}

.cardImageBox svg {
    max-width: 100%;
    font-size: 5rem;
    font-weight: 100;
    object-fit: contain;
}


.blContainer{
    /* -webkit-backdrop-filter: blur(5px);  
    backdrop-filter: blur(5px); */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

}
.optionsBox {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;
    margin-top: 2rem;
}
.optionsList{
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1rem;
}
.optionsBox a {
    color: black;
    text-decoration: none;
}


.textBox {
    font-family: var(--slim-title-font-family);
    display: flex;
    flex-direction: column;
    gap: 1rem;


    background-color: var(--color-gray-06);
    /* margin-left: 2rem; */
    padding: 2rem;
    /* margin: 0 2rem; */
    /* margin: 8rem 0 0 0; */
    border-radius: 16px;
}
#purchaseProTextBox{
    background-color: var(--color-gray-06);
    border: var(--color-gray-04);
    
}

@media (min-width: 514px) {
    .buttonBoxWrapper{
        flex-direction: row;
    }
    .card {
        border-radius: 16px;
        margin: 0 ;
    }
    #purchaseProTextBox{
        width: 70%;
    }
    .featureSection {
        margin: 0 0rem 2rem 0rem;
    }

}
@media (min-width:768px) {
    .card {
        /* margin: 0 2rem; */
    }
    /* .featureSection {
        display: grid;
        column-gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 8rem;
    } */

  
}
@media (min-width: 1080px) {
    /* .featureSection {
        display: grid;
        column-gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 8rem;
    } */
   
    .heroSection {
        height: 70rem;
        /* padding: 0 4rem; */
    }

    .introTextBox {
        padding: 5rem 0rem 4rem 0rem;
    }

    .purchaseProSectionWrapper {
        /* height: 70rem; */
        /* height: 50vh; */
        background-attachment: fixed;
        background-size: cover;
        background-position: bottom;
    }

    .textBox {
        gap: 2rem;
    }

    .purchaseProSection {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        /* max-width: 180rem;
        margin: 0 auto; */
    }
    #purchaseProTextBox {
        grid-column: 1/7;
        /* margin: 0 4rem; */
        padding: 4rem;
        width: auto;
    }
    .featureSection {
        display: grid;
        column-gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 8rem;
    }
}
/* @media (min-width: 1280px) {} */
@media (min-width: 1440px) {
    .cardTextBox {
        padding: 2rem;
    }
    #purchaseProTextBox {
        grid-column: 1/6;
        margin: 0 4rem;
        padding: 4rem;
    }
}
@media (min-width: 1810px) {
    .heroSection {
        height: 80rem;
        /* padding: 0 4rem; */
    }
    #purchaseProTextBox {
        margin: 0;
    }
    .card {
        margin: 0;
    }
       .introTextBox {
        padding: 5rem 0 4rem 0;
    }
    .featureSection{
        margin: 0 0 4rem 0;
    }
}