
.pageHeaderContainer{}
.textBox {
    display: flex;
    flex-direction: column;
}

.titleText {
    font-size: var(--text-size-03);
    font-family: var(--semiBold-title-font-family);
    color: black;
}

.subtitle {
    font-size: 2rem;
    /* color: var(--color-gray-02); */
}