.featureLogoSectionWrapper {
    background-color: var(--color-gray-06);
   
}



.titleText {
    font-size: var(--text-size-2);
    /* line-height: 1.8; */
    color: var(--color-gray-03);
    font-family: var(--slim-title-font-family);


}

.titleBox {
    margin-bottom: 2rem;
}

.logoWrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 2rem;

    margin-top: 0rem;
    justify-content: flex-start;
   
    padding: 2rem 0; 
 
}



.logo {
  
    width: 8rem; 
    height: 8rem;
    background-color: var(--color-gray-07);
    border-radius: 1rem;
    padding: 1rem;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 430px) {
    .logo {
  
        width: 11rem; 
        height:11rem;
       
    }
}
@media (min-width: 1080px) {

   .logo {
      
        width: 14rem; 
        height: 14rem;
     
    }
}

@media (min-width: 1810px) {}

@media (min-width: 1920px) {}

