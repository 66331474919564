.logoContainer{
    height: auto;
     display: flex;
    align-items: center;
    justify-content: center;
  

}

.logoContainer img{
    width: 18rem;
    /* width: 20rem; */
    height: auto;
    object-fit: contain;
}
@media (min-width:514px){
   
}
@media (min-width:768px) {
    /* .logoContainer{
        height: 10rem;
    } */
  
}
@media (min-width:1080px) {
    .logoContainer img{
        width: 30rem;
       
    }
   
}
@media (min-width:1440px) {
    
    /* .logoContainer{
        height: 13rem;
    } */
}
@media (min-width:1920px) {}