 /* DONE */
 .productHeaderContainer{
    margin-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
   
  }
  .productHeaderTextWrapper{
    display: flex ;
    flex-direction: column;
    gap: 1rem;
  }
  .colorLegendWrapper{
    margin-bottom: 2rem;

  }
  .titleText{
    color: black;
    font-family: var(--slim-title-font-family);
    font-size: var(--text-size-03)
  }
  .subtitleText{
    color: black;
    font-family: var(--semiBold-title-font-family);
    font-size: var(--text-size-04)
  }
  .availabilityText{
    font-size: var(--text-size-00);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03);
  }
@media (min-width: 768px) {
    /* .titleText{
        font-size: 3.5rem;
      }
      .subtitleText{
        font-size: 2.5rem;
      }
      .availabilityText{
        font-size: 1.8rem;
      } */
}
@media (min-width: 1440px) {
    /* .titleText{
        font-size: 4rem;
      }
      .subtitleText{
        font-size: 3rem;
      }
      .availabilityText{
        font-size: 1.8rem;
      } */
}
@media (min-width: 1830px) {
   
}
@media (min-width: 1920px) {

    
}

@media print {
  .productHeaderContainer{
    /* page-break-inside: avoid;
    page-break-before: avoid-page;
    page-break-after: avoid-page; */
    /* margin-top: 3rem;
    padding-bottom: 2rem; */
    page-break-inside: avoid;
    /* margin-top:2rem;
    padding-top: 1rem; */
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid var(--color-gray-03);
    border-bottom: 1px solid var(--color-gray-03);
    /* height: 100%; */
   
  }
  .productHeaderTextWrapper{
    display: flex ;
    flex-direction: column;
    gap: 0rem;
  }
  .titleText {
    /* margin-bottom: 1rem; */
    font-family: var(--semiBold-title-font-family);
    /* font-size: var(--text-size-00); */
    font-size:1.4rem;
  }
  .subtitleText {
    font-family: var(--slim-title-font-family);
    font-size:1.4rem;
  }
  .colorLegendWrapper{
    margin: 0rem;
    display: none;
  }
  .buttonBoxWrapper{
    display: none;
  }
  .availabilityText{
    display: none;
  }

}