.searchHeaderTextContainer {
    color: white;
}

.titleText {
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
}

.subtitleText {
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
}