.specsSectionWrapper {
  /* background-color: var(--color-gray-06);
  border-top: 2px solid var(--color-gray-05); */
  /* border-top: 1px solid var(--color-gray-04);
  border-bottom: 1px solid var(--color-gray-04); */
}

/* DONE */
.specsContainer {
  /* background-color: var(--color-gray-06); */
  /* margin: 3rem 2rem 0 2rem; */
  margin: 3rem 0 0 0;
  padding: 0 0 4rem 0;
  /* padding: 2rem 2rem 4rem 2rem; */
  /* border-radius: 14px; */
}

.specListTitleBox{
  margin-top: 2rem;
}

.specListTitleText {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: var(--text-size-02);
  font-family: var(--semiBold-title-font-family);
  color: var(--color-gray-02);
  /* border-bottom: 1px solid var(--color-gray-04); */
}

.specificationsList{
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.specListItemText {
  list-style: none;
  font-size: var(--text-size-01);
  color: var(--color-gray-03);
  /* margin: .5rem 0; */
}

.specsWrapper {
  /* margin-right: 3rem; */
}

.specificationsContainer {
  display: flex;
  flex-direction: row;
}

.specificationsColoumn {
  /* margin-top: 2rem; */
}

@media (min-width: 768px) {
  .specsContainer {
   
    /* padding: 2rem ; */
  }
  .specListTitleText {

    padding-bottom: 0rem;
    margin-bottom: 1rem;
    /* margin: 2rem 0 0 0; */
  }


}

@media (min-width: 1080px) {
  .specsContainer {
   
    /* padding: 4rem ; */
  }

  .specsContainer {
    /* max-width: 180rem;
    margin: 0 auto; */
    /* padding: 4rem 8rem ; */
  }
}

@media (min-width: 1440px) {
  .specsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 8rem;
    justify-items: flex-start;
  }

  .specificationsColoumn {
    display: flex;
    flex-direction: column;
    /* gap: 2rem; */
  }

  .specificationsList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
  }



  /* .specListItemText {
    font-size: 1.5rem;
    margin: 1rem 0;
  } */
  .specsWrapper {
    /* margin-right: 0rem; */
  }

  .specsContainer {
    /* padding: 2rem 8rem 4rem 8rem; */
  }

}

@media (min-width: 1830px) {
  .specsContainer {
   
    /* padding: 2rem 0 4rem 0 ; */
  }
}
@media (min-width: 1920px) {
  .specsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  }
}


@media print {
  .specsContainer {
    margin: .2rem 0;
    padding: .2rem 0;
  }

  .specsSectionWrapper {
    /* page-break-inside: avoid; */
    background-color: var(--color-gray-06);
    padding-top: 0rem;
    /* margin-top: 1rem; */
    border-top: 0;
    border-bottom: 0;
  }

  .specsWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 1rem;
    justify-items: flex-start;
    margin-right: 0;
    border-bottom: 1px solid var(--color-gray-03);
    padding-bottom: 1rem;
  }

  .specListItemText {

    margin: .2rem 0;
    font-size: 1.2rem;
  }

  .specListTitleText {

    font-family: var(--semiBold-title-font-family);
    font-size: 1.4rem;
    /* margin-bottom: 1rem; */
    padding-bottom: .5rem;
    border: none;
  }

  .specificationsList {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-top: 0rem;
    font-size: 1.2rem;
  }

  /* .specificationsColoumn {
    font-size: 1rem;
  } */

  .specificationsColoumn li {
    /* font-size: var(--text-size-00); */
    font-size: 1.2rem;
    /* line-height: normal; */
  }


}