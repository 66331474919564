

.modal {
    position: fixed;
    /* right: 0%; */
    left: 0;
    top: 0%;
    width: 100%;
    z-index: 100;
    background-color: var(--color-gray-06);
    display: flex;
    justify-content: center;

}

.mainBuilderListContainer {
    position: absolute;
    width: 100%;
    /* width: 120rem; */
    /* height: 80rem; */
    height: 100vh;
    /* border: 1px solid #ccc; */
    background-color: var(--color-gray-07);
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* justify-content: space-between; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
    /* border-radius: 16px; */
    padding: 5rem 2rem;
}
.headTextBox{
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    /* border-bottom: 1px solid var(--color-gray-05); */
    /* margin-bottom: 1rem; */
}
.titleText {
    font-size: var(--text-size-04);
    display: inline;
    font-family: var(--slim-title-font-family);
    color: black;
  
}
.subtitleText {
    font-size: var(--text-size-02);
    color: var(--color-gray-01);
    /* text-transform: uppercase; */
    font-family: var(--slim-title-font-family);
  display: flex;
}
.countTextBox{
display: flex;
flex-direction: row;
}
.closeDropdownButtonBox{
    margin-left: auto;
    /* margin-top: -2rem; */
}
.closeDropdownButtonBox svg :hover{
    cursor: pointer;
}
.bodyWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    /* margin-bottom: 5rem; */
}
.bodyWrapper span{
    display: flex;
}

.productCountNumber{
    color: var(--color-red-03);
    margin-left: 1rem;
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);

}
.textTitleCountBox{
    display: flex;
    flex-direction: column;
}
.modalButtonBox{
    margin: 0 2rem;
    /* border-bottom: 1px solid var(--color-gray-04); */
}
.modalButtonBox button {
    margin-top: 2rem;
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;

}
.modalButtonBox a {
   /* color: white; */
   text-decoration: none;

}
@media (min-width:514px) {
  
    .mainBuilderListContainer {
        width: 50rem;
        right: 0;
    }
}
@media (min-width:768px) {}
@media (min-width:1080px) {
    .mainBuilderListContainer {
        width: 50rem;
        right: 0%;
    }
 
}
@media (min-width:1440px) {}
@media (min-width:1920px) {}