.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
   
    /* padding: 0 0 5rem 0; */
    /* max-width: 180rem;
    margin: 0 auto; */
    padding: 0 0 10rem 0;
}
.containerWrapper{
    background-color: var(--color-gray-06);
    border-top: 1px solid var(--color-gray-04);
  
}
.productCount{
    color: var(--color-red-01); 
    font-weight: bold; 
    border-bottom: 1px solid var(--color-gray-04);
}
.textBox{
    margin: 0 2rem 0 2rem ;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: var(--slim-title-font-family);
}
.titleText{
    font-size: 5rem;
    color: black;
   
}
.bodyText{
    font-size: 2.5rem;
    color: var(--color-gray-02);
    color: var(--color-gray-02);
}
.imageWrapper {
    /* height: 100%; */
    width: auto;
    display: flex;
}

.imageWrapper img {
    height: 27rem;
    width: auto;
    background-size: contain;
}



@media (min-width:514px) {}
@media (min-width:768px) {
.titleText{
    font-size: 7rem;
}
.bodyText{
    font-size: 3rem;
}
}
@media (min-width:1080px) {
    /* .container{
        height: 50rem;
    } */
    .imageWrapper img {
        height: 37rem;
    }
    .container
    {
        padding: 0rem 30rem 8rem 4rem;
    }
    
}
@media (min-width:1440px) {
    .container{
        height:70rem;
    }
}
@media (min-width:1920px) {
  
    
    .imageWrapper img {
        height: 50rem;
       
    }
}