.chartContainer {
    /* width: 50rem; */
    background-color: white;
    /* border: 1px solid var(--color-gray-05); */
    padding: 2rem 2.2rem 4rem 2.2rem;
    margin-top: 1rem;
    border-radius: 16px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.chart {
    padding: 1rem;
    /* border-radius: 16px; */
    background-color: var(--color-gray-05);
    border: 1px solid var(--color-gray-04);
    text-align: center;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    height: 20rem;
}

.chartTextBox {
    margin-bottom: 1rem;
    font-family: var(--slim-title-font-family);
}

.chartTitle {
    font-size: var(--text-size-02);
    color: black;
    /* border-bottom: 1px solid var(--color-gray-01); */
}

.chartSubtitle {
    font-size: var(--text-size-02);
    color: black;

}

.chartBar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chartBarInner {
    height: 100%;
    min-width: 5rem;
    max-width: 7rem;
  
    border: none;
    /* background-color: var(--color-gray-03); */
    /* border: 1px solid var(--color-gray-04); */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chartBarFill {
    background-color: var(--color-gray-07);
    border: 1px solid var(--color-gray-03);
    width: 100%;
    transition: all 0.3s ease-out;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.chartBarLabel {

    font-size: var(--text-size-00);
    text-align: center;
    color: var(--color-gray-01);
    border-top: 1px solid var(--color-gray-04);
    margin-right: 1rem;
}


/* .chartBarValue {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
} */

/* .chartBarLegend {
    display: flex;
    align-items: center;
}

.legendColor {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.legendLabel {
    font-size: 0.8rem;
} */
.stat {
    color: var(--color-red-03);
    font-size: var(--text-size-01);
}

.leaderBoardTable {
    background-color: white;
    padding: 2rem;
    border-radius: 16px;
}

.leaderRow {
    background-color: var(--color-gray-06);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid var(--color-gray-05);
    /* background-color: whitesmoke; */
    padding: 1rem;
    align-items: center;
}

.rank {
    font-size: 1.7rem;
    font-weight: bold;
}

.rankNumber {
    color: var(--color-red-02);
    font-weight: bold;
}

.leaderData {

    display: flex;
    flex-direction: column;
}

.leaderPoint {
    font-size: 1.7rem;
}