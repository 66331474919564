/* .loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.75);
  z-index: 1;
}

.logoSpinnerContainer{
  position: relative;
  bottom: 30%;
  right: 10%;

}
.loader {
  border: 8px solid white;
  border-radius: 50%;
  border-top: 8px solid var(--color-red-03);
  width: 20rem;
  height: 20rem;
  box-shadow: 0 0 16px rgba(225, 224, 224, 0.5);
  animation: spin .5s linear infinite;
  position: absolute;

}


.gif{
  position: absolute;
  top: 143px;
  left:-27px;
  right: 10px;
  width: 20rem;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.gif {
  width: 15rem;
  height: auto;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .loader {
    width: 10rem;
    height: 10rem;
  }

  .gif {
    width: 12rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1440px) {
  .loader {
    width: 12rem;
    height: 12rem;
  }

  .gif {
    width: 15rem;
    margin-bottom: 3rem;
  }
}

@media (min-width: 1920px) {
  .loader {
    width: 40rem;
    height: 40rem;
  }

  .gif {
    width: 30rem;
    margin-bottom: 4rem;
  }
} */
.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Prevent any overflow from animations */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f6f3ebab;
  background-color: rgb(0 0 0 / 71%);
  /* background: rgba(0,0,0,0.75); */
  z-index: 1;
}

.logoSpinnerContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 15rem;
}

.loader {
  border: 8px solid white;
  border-radius: 50%;
  border-top: 8px solid var(--color-red-03);
  width: 20rem;
  height: 20rem;
  box-shadow: 0 0 16px rgba(225, 224, 224, 0.5);
  animation: spin .5s linear infinite;
  position: absolute;
  z-index: 2; /* Loader should be above the logo */
}

.gif {
  position: absolute;
  width: 80%; /* Adjust based on the desired logo size inside the loader */
  z-index: 1; /* Logo should be below the loader */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media queries can adjust the width and height of logoSpinnerContainer */
@media (min-width: 768px) {
  .logoSpinnerContainer {
    width: 20rem;
    height: 20rem;
  }
  .loader{
    width: 25rem;
    height: 25rem;
  }
}

@media (min-width: 1440px) {
 
}

@media (min-width: 1920px) {

}

