.modalContainer {
    z-index: 100;
    position: fixed;
    top: 22vh;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    background-color: var(--color-gray-07);
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 2rem;
}

.titleText{
    font-size: var(--text-size-05);
    color: black;
}
.subtitleText{
    font-size: var(--text-size-02);
    color: var(--color-gray-03);
}
.headlineText{
    font-size: var(--text-size-02);
    color: var(--color-gray-03);
}


.modalHeaderWrapper {
    /* width: 100%; */
    /* padding: 1rem 0.5rem; */
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    /* padding: 2rem; */
    /* background-color: var(--color-gray-05); */
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-04);
    padding-bottom: 1rem;
    /* line-height: normal; */
}

.modalHeaderWrapper svg {
    display: flex;
    margin-left: auto;
    /* font-size: var(--subtitle-font-size); */
}
.headerIconWrapper{
    display: flex;
    justify-content: flex-end;

}

.modalFormContainer{
  padding: 2rem 0;
}

.modalFormContainer label{
     font-size: var(--body-font-size);
    color: var(--color-gray-02);
}
.modalFormContainer input{
   margin: 1rem 0;
}
.modalFormContainer section{
    display: flex;
    column-gap: 1rem;
}
.modalFormContainer button{
   margin: 1rem 0 0 0;
}
.modalFormContainer  div{
    position: relative;
    
}
.modalFormContainer  div > svg{
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: var(--color-gray-03);
    
}
.inputIcon{
 position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
}


@media (min-width:514px){
    .modalContainer {
        width: 80%;
    }
}
@media (min-width:768px){
    .modalContainer {
        width: 60%;
        padding: 4rem;
    }
}
@media (min-width:1080px){
    .modalContainer {
        width: 40%;
    }
    .modalFormContainer button{
        margin: 2rem 0 0 0;
     }
 
}
@media (min-width:1440px){
    .modalContainer {
        width: 40%;
    }
    .modalFormContainer button{
        margin: 4rem 0 0 0;
     }
}
@media (min-width:1920px){
    .modalContainer {
        width: 25%;
        padding: 4rem;
    }
}