

.imageBox {
    width: 100%;
    height: 100%;
    /* background-color: white; */
    margin-top: 2rem;
    border-radius: 16px;
  
}

.imageBox img {
    width: 100%;
    height: 100%;
    object-fit: contain;  
    border-radius: 16px;
}
@media (min-width:514px) {
  
}
@media (min-width:768px) {}
@media (min-width: 1080px) {}
@media (min-width: 1440px) {
    .imageBox {
        margin-top: 4rem;
        border-radius: 16px;
    }
}
