button {
    padding: 1.2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 200px;
    font-size: var(--button-font-size);
    /* width: 100%; */
}

button a {
    text-decoration: none;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: none;
    font-size: var(--button-font-size);
}

.buttonLinks a {
    text-decoration: none;
}
.buttonIcon{
    background-color: aqua;
}
.buttonIconWrapper svg {
    margin-right: .5rem;
    display: flex;
}

.default {
    background-color: white;
    border: 1px solid var(--color-gray-04);
    color: black;
}

.white {
    background-color: white;
    border: 1px solid black;
    color: black;
}

.black {
    background-color: black;
    color: white;
    border: 1px solid black;
}
.blackIconButton {
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 1rem 2rem;
    display: flex;
}
.redIconButton {
    background-color: var(--color-red-03);
    color: white;
    border: 1px solid var(--color-red-03);
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
}
.whiteIconButton {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
}
.listIconButton {
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 1rem 2rem;
    display: flex;
}
.listIconButton svg{
    margin-left: 1rem;
}
.blackIconButton2 {
    background-color: black;
    color: white;
    border: 1px solid black;
    padding: 1rem 2rem;
    display: flex;
    /* gap: 1rem; */
    align-items: center;
}
.blackIconButton2 svg {
    color: white;
    margin-left: .5rem;
}

.blackIconButton svg {
    color: white;
}
.redIconButton svg {
    color: white;
}

.darkGray {
    background-color: var(--color-gray-01);
    color: white;
    border: 1px solid var(--color-gray-01);
}

.grayButtonIcon {
    background-color: var(--color-gray-05);
    color: black;
    border: 1px solid var(--color-gray-04);
}

.grayButtonIcon svg {
    font-size: var(--icon-size);
}

.grayButtonIcon a {
    font-size: var(--icon-size);
}

.lightButtonIcon {
    background-color: var(--color-gray-05);
    color: var(--color-gray-01);
    border: 1px solid var(--color-gray-04);
}

.red {
    background-color: var(--color-red-03);
    border: 1px solid var(--color-red-03);
    color: white;
}

.red a,
.black a {
    color: white;
}

.red svg {
    color: white;
}

.outLinkButton {
    background-color: inherit;
    border: 1px solid black;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    border-radius: 16px;
}

.lightIconButton {
    height: 3rem;
    width: 3rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: var(--color-gray-05);
    border: 1px solid var(--color-gray-03);
}

.lightIconButton svg {
    margin: 0;
    font-size: var(--button-text-font-size);
    color: var(--color-gray-01);
}

.baseIconButton {
    height: 3rem;
    width: 3rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: var(--color-gray-04);
}

.baseIconButton svg {
    margin: 0;
    font-size: var(--body-font-size);
    color: black;
}

.transIconButton {
    height: 3rem;
    width: 3rem;
    padding: 2rem;
    border-radius: 50%;
    /* border: 1px solid white; */
    background-color: inherit;
}

.transIconButton svg {
    margin: 0;
    font-size: var(--body-font-size);
    color: white;
}

.warningButtonIcon {
    height: 3rem;
    width: 3rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: var(--color-gray-05);
    border: 1px solid var(--color-red-01);
}

.warningButtonIcon svg {
    margin: 0;
    font-size: var(--body-font-size);
    color: var(--color-red-01);
}

.successButtonIcon {
    height: 3rem;
    width: 3rem;
    padding: 2rem;
    border-radius: 50%;
    background-color: var(--color-gray-05);
    border: 1px solid green;
}

.successButtonIcon svg {
    margin: 0;
    font-size: var(--body-font-size);
    color: green;
}

@media (min-width:514px) {}

@media (min-width:768px) {
    button:hover {
        cursor: pointer;
    }
}

@media (min-width:1080px) {
    .black:hover {
        background-color: var(--color-red-03);
        border: 1px solid var(--color-red-03);
    }
    .lightIconButton:hover {
        background-color: var(--color-gray-04);
    }
    .red:hover {
        background-color: black;
        border: 1px solid black;
    }
    .lightButtonIcon:hover {
        background-color: var(--color-gray-04);
    }
    .darkGray:hover {
        background-color: var(--color-red-02);
        color: white;
        border: 1px solid var(--color-red-03);
    }
    .red:hover {
        background-color: black;
        border: 1px solid black;
    }
    .grayButtonIcon:hover {
        background-color: var(--color-gray-04);
    }
    .blackIconButton:hover {
        background-color: var(--color-red-03);
        color: white;
        border: 1px solid var(--color-red-03);
       
    }
    .redIconButton:hover {
        background-color: black;
        color: white;
        border: 1px solid black;
       
    }
    
    .blackIconButton2:hover {
        background-color: var(--color-red-03);
        color: white;
        border: 1px solid var(--color-red-03);
    }
    
}

@media (min-width:1440px) {}

@media (min-width: 1920px) {}