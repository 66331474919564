.resourceFormSectionContainer{
    margin-top: 4rem;
    border: 2px dotted var(--color-gray-04);
    border-radius: 2rem;
    padding: 3rem;
}

.mainContainer{
    /* padding: 2rem 0; */
  
}
.headerWrapper{
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headerCountWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.bodyWrapper{
    padding: 2rem 0;
   /* background-color: var(--color-gray-04); */
}

.resourceGroupWrapper{
    /* background-color: var(--color-gray-05); */
    border-top: 1px solid var(--color-gray-04);
    padding: 2rem;
}

.resourceFormTitle{
    font-size: var(--text-size-02);
    font-family: var(--semiBold-title-font-family);
    color: black;
}
.resourceFormSubtitle{
    font-family: var(--slim-title-font-family);
    font-size: var(--text-size-01);
    color: var(--color-red-02);
}
.groupContainer{}
.groupHeader{
    padding-bottom: 2rem;
}
.groupHeaderTitle{}

.formElementsInputs{
    display: flex;
    flex-direction: column;

    /* padding: 4rem; */
    width: 100%;
    /* margin-bottom: 4rem; */
    gap: 1rem;
}
.formElementLabel{
    font-size: 2rem;
    font-family: var(--slim-title-font-family);
}
.formElement{

    width: 100%;   
}
.formInputBreak{
    /* margin-top: 1rem;
    margin-bottom: 2rem;
    margin-left: 2rem; */
}
.formElementsUploader{}
.groupFooter{}
.footerButtonWrapper{
    display: flex;
}
.footerButtonWrapper button{
    width: max-content;
}



.groupTitle{
    /* padding: 1rem; */
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}
.formElementsWrapper{
    display: flex;
    flex-direction: column;
   /* align-items: center; */
 
    border: 1px solid black;
    width: 50%;
}
.inputsWrapper{

    display: flex;
    flex-direction: column;
}
.uploadsWrapper{
 
}
.buttonWrapper{
    display: flex;
}
.buttonWrapper button{
    max-width: max-content;
}
.resourceFormButton{
    background: black;
    color: white;
}
.removeGroupButton{
    background-color: white;
}
.headerButtonWrapper{
    margin-top: 2rem;
}
.groupContainer{
 
}
.groupFormElements{
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .formElementsInputs{
        display: flex;
        flex-direction: row;
    
        padding: 2rem 0;
        width: 100%;
    }
    .formInputBreak{
        margin-left: 2rem;
    }
}