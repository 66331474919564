:root {
  --color-red-01: #A50034;
  --color-red-02: #EA1917;
  --color-red-03: #ED0602;
  --color-gray-01: #262626;
  --color-gray-02: #4A4946;
  --color-gray-03: #716F6A;
  --color-gray-04: #D0CBC1;
  --color-gray-05: #E6E1D6;
  --color-gray-06: #F0ECE4;
  --color-gray-07: #F6F3EB;

  --text-size-000: 1.4rem;
  --text-size-00: 1.7rem;
  --text-size-01: 1.8rem;
  --text-size-02: 2rem;
  --text-size-03: 2.4rem;
  --text-size-04: 2.4rem;
  --text-size-05: 2.8rem;
  --nav-link-font-size: 2rem;

  --icon-size: 2.5rem;
  --button-font-size: 1.6rem;

  --slim-title-font-family: "LG EI Headline Light", "Microsoft Sans Serif", sans-serif;
  --title-font-family: "LG EI Headline Regular", "Microsoft Sans Serif", sans-serif;
  --semiBold-title-font-family: "LG EI Headline SemiBold", "Microsoft Sans Serif", sans-serif;
  --body-font-family: "LG EI Text Regular", "Microsoft Sans Serif", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

}
/* 
TEST
*/

body {
  margin: 0;
  font-family: "LG EI Text Regular", "Microsoft Sans Serif", sans-serif;
  /* line-height: 1.6; JUST REMOVED*/
  background-color: #F6F3EB;
  min-height: 100vh;

  /* DEFAULT REACT */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  
}
/* NEW */
a:hover{
  cursor: pointer;
}
.slide-in-left-enter{
  transform: translateX(-100%);
}

.slide-in-left-enter-active{
  transform: translateX(0%);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit{
  transform: translateX(0%);
  opacity: 1;
}


@font-face {
  font-family: "LG EI Headline Light";
  font-style: normal;
  font-weight: normal;
  src: url("../src/Shared/Theme/Fonts/LG_EI_Headline_Light.ttf") format("truetype");
  src: url("../src/Shared/Theme/Fonts/LG_EI_Headline_Light.woff") format("woff");
}

@font-face {
  font-family: LG EI Text Regular;
  font-style: normal;
  font-weight: normal;
  src: url("../src/Shared/Theme/Fonts/LG_EI_Text_Regular.ttf") format('truetype');
  src: url("../src/Shared/Theme/Fonts/LG_EI_Text_Regular.woff") format('woff');
}

@font-face {
  font-family: LG EI Text SemiBold;
  font-style: normal;
  font-weight: normal;
  src: url("../src/Shared/Theme/Fonts/LG_EI_Text_SemiBold.ttf") format('truetype');
  src: url("../src/Shared/Theme/Fonts/LG_EI_Text_SemiBold.woff") format('woff');
}

@font-face {
  font-family: LG EI Text Bold;
  font-style: normal;
  font-weight: normal;
  src: url("../src/Shared/Theme/Fonts/LG_EI_Text_Bold.ttf") format('truetype');
  src: url("../src/Shared/Theme/Fonts/LG_EI_Text_Bold.woff") format('woff');
}

@font-face {
  font-family: LG EI Headline Regular;
  font-style: normal;
  font-weight: normal;
  src: url("../src/Shared/Theme/Fonts/LG_EI_Headline_Regular.ttf") format('truetype');
  src: url("../src/Shared/Theme/Fonts/LG_EI_Headline_Regular.woff") format('woff');
}

@font-face {
  font-family: LG EI Headline SemiBold;
  font-style: normal;
  font-weight: normal;
  src: url("../src/Shared/Theme/Fonts/LG_EI_Headline_Semibold.ttf") format('truetype');
  src: url("../src/Shared/Theme/Fonts/LG_EI_Headline_Semibold.woff") format('woff');
}

@media (min-width:514px) {}

@media (min-width:768px) {
  :root {
  
  }
}

@media (min-width:1080px) {
  :root {
    --text-size-00: 1.7rem;
    --text-size-01: 2rem;
    --text-size-02: 2.4rem;
    --text-size-03: 3.6rem;
    --text-size-04: 4.4rem;
    --text-size-05: 5.6rem;
    --title-font-size: 6.4rem;
    --headline-font-size: 4.4rem;
    --icon-size: 3rem;
    --big-body-font-size: 3rem;
  }
}

@media (min-width:1440px) {}

@media (min-width:1920px) {}


/* DEFAULT REACT */
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */