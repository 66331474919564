.overlayContentContainer {
    position: fixed;
    top: 0%;
    /* left: 50%;
    transform: translateX(-50%); */
    z-index: 1000;
    background-color: var(--color-gray-05);
    width: 100%;
    height: 100%;
    padding: 2rem 0;
}

.overlayContentWrapper {

    
}