.displayDataContainer{
        display: flex;
        flex-direction: column;
        align-items: center; /* Center the tiles in the container */
        gap: 2rem;
        /* border-bottom: 1px solid var(--color-gray-03); */
       padding: 2rem 0;
        /* background-color: aqua; */
        /* justify-content: center; */
}

@media (min-width: 514px) {
  /* .displayDataContainer{
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
} */
.displayDataContainer {
  display: grid;
  gap: 2rem;
  /* grid-template-columns: repeat(auto-fill, 200px); */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
}

@media (min-width: 768px) {
  /* .displayDataContainer {
    display: grid;
    gap: 2rem;
    
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  } */

  /* .dataTilesContainer {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(min, 1fr);
  } */
}

@media (min-width: 1080px) {
    /* .userDataTilesContainer {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(5, 1fr);
      } */
}
@media (min-width: 1280px) {
    .displayDataContainer {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(5, 1fr);
      }
}

@media (min-width: 1440px) {}