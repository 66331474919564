.heroContainer{
    height: 50rem;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    /* align-items: flex-start; */
    /* margin-top: 8rem; */
    border-radius: 16px;
    padding: 0 2rem;
}
.textBox{
    margin-top: 5rem; 
    font-family: var(--slim-title-font-family);
    display: flex;

    height: inherit;
    padding-top: 10rem;
    /* line-height: normal; */
}
.titleText{
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
}
.topTitleText{
    font-size: var(--text-size-01);
}
.subtitleText{
    font-size: var(--text-size-02);
}
.linkedSubtitleText{
    font-size: var(--text-size-02);
}
/* SUBTITLE ICON WRAPPER */
.navLinkTextWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.navLinkTextWrapper a{
text-decoration: none;
    color: white;
}
.navLinkTextWrapper svg{
    font-size: 3rem;
    margin-left: 1rem;
}



@media (min-width:514px) {
   
}
@media (min-width:768px) {
    .heroContainer {
        height: 60rem;
      }

}
@media (min-width:1080px) {
    .heroContainer{
        /* height: 50vh; */
          align-items: center;
          /* border-radius: 16px; */
          /* margin-top: 11rem; */
         
    }
    .heroMarginWrapper{
        /* max-width: 180rem;
        margin: 0 auto; */
        /* margin-right: 8rem;
        margin-left: 8rem; */
       }
    .textBox{
        margin-left: 4rem;
    }
}
@media (min-width:1280px) {
    /* .heroContainer{
        height: 60vh; 
    } */
}
@media (min-width:1440px) {
    .heroContainer{
        /* height: 80vh; */
    }
    
}
@media (min-width: 1860px) {
    .heroContainer {
    
      border-radius: 16px;
  
    }
  
  }
@media (min-width:1920px) {
    /* .textBox{
        margin-left: 20rem;
    } */
}
