.modalContainer {
    z-index: 1000;
    position: fixed;
    top: 22vh;
    left: 50%;
    /* This will place the left edge at the middle of the viewport */
    transform: translateX(-50%);
    /* This will pull the modal back to its center */
    width: 90%;
    /* Adjust width as desired, e.g., 500px, 60%, etc. */
    background-color: var(--color-gray-07);
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.titleText{
    color: black;
    font-size: var(--text-size-02);
    font-family: var(--semiBold-title-font-family);
  
}
.bodyText {
    font-size: var(--text-size-01);
    color: black;
    font-family: var(--slim-title-font-family);
}




.modalHeaderWrapper {
    /* width: 100%; */
    /* padding: 1rem 0.5rem; */
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom: 1px solid var(--color-gray-05);
    padding: 2rem;
    background-color: var(--color-gray-06);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.modalHeaderWrapper svg {
    display: flex;
    font-size: var(--body-font-size);
}

.modalMessageWrapper {
    padding: 2rem;
}

.modalActionBoxWrapper, .modalConfirmationBoxWrapper {
    display: flex;
    flex-direction: row;
    padding: 2rem;
    column-gap: 1rem;
}

.errorListWrapper {
    padding: 2rem;
}

.errorList li {
    color: var(--color-red-03);
    font-size: var(--text-size-01);
    list-style: inside;
    list-style-position: inside;
}

@media (min-width:514px){
    .modalContainer {
        width: 80%;
    }
}
@media (min-width:768px){
    .modalContainer {
        width: 60%;
    }
}
@media (min-width:1080px){
    .modalContainer {
        width: 45%;
    }
}
@media (min-width:1440px){
    .modalContainer {
        width: 30%;
    }
}
@media (min-width:1920px){
    .modalContainer {
        width: 20%;
    }
}