.categorySectionContainer{
  margin-top: 10rem;
  /* background-color: aquamarine; */
  /* margin: 10rem;
  padding: 10rem; */
  /* margin-bottom: 15rem; */
}
.gridContainer {
  margin-top: 10rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 10rem;
  margin-top: 12rem;
}

.categorySectionWrapper {
  height: 40rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  align-items: center;

}

.sectionTitleText {
  font-size: var(--text-size-05);
  font-family: var(--semiBold-title-font-family);

}

.categoryBox a {
  text-decoration: none;
  color: white;
}

.sectionSubtitleText {
  font-size: var(--text-size-00);
  font-family: var(--slim-title-font-family);
  color: white;

}

.categoryBox {
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: stretch;
  margin-top: 14rem;
  line-height: normal;
}

.iconBox {
  margin-left: 2rem;
}

@media (min-width: 514px) {
  .categorySectionWrapper {
    height: 50rem;
  }
  .categoryBox {
    margin-top: 18rem;
  }
  
}

@media (min-width: 768px) {

  .gridContainer {
    margin-top: 14rem;
  }

  .categorySectionWrapper {
    /* height: 60vh; */
    
  }

  /* .sectionTitleText {
    font-size: 7rem;
  }

  .sectionSubtitleText {
    font-size: 3rem;
  } */

  .categoryBox {
    margin-top: 26rem;
  }
}

@media (min-width: 1080px) {
  .categorySectionContainer{
    margin-top: 19rem;
  
  }
  .gridContainer {
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 8rem;
  }

  .categorySectionWrapper {
    /* height: 70vh; */
    background-attachment: fixed;
  }

}
@media (min-width:1280px) {
  /* .heroContainer{
      height: 60vh;
  } */
}
@media (min-width: 1440px) {

  .categorySectionWrapper {
    height: 60rem;
  }

}

@media (min-width: 1860px) {
  /* .categorySectionWrapper {
    border-radius: 16px;
  } */
}
@media (min-width: 1920px) {
  .gridContainer {
    /* margin-left: 8rem;
    margin-right: 8rem; */
  }



  .categoryBox {
    padding: 4rem 0 0 4rem;
  }


}