.portalBodyContainer {
  background-color: var(--color-gray-06);
  min-height: 100rem;
  padding: 6rem 2rem;
  /* border-radius: 16px; */
  /* margin-top: 1rem; */

}

.portalContentWrapper {
  max-width: 160rem;
  margin: 0 auto;
  padding-top: 1rem;

}

@media (min-width:514px) {
  .portalBodyContainer {
    /* padding: 2rem 4rem 2rem 4rem; */
    padding: 6rem 2rem;
  }
}

@media (min-width:768px) {}

@media (min-width:1080px) {
  .portalBodyContainer {
    min-height: 100rem;
    
    padding: 2rem 4rem 2rem 4rem;
    border-left: 1px solid var(--color-gray-04);

  }
}

@media (min-width:1440px) {}

@media (min-width:1920px) {}