.logoContainer{
    height: 8rem;
    /* width: 8rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer img{
    /* width: 100%; */
    height: 100%;
    object-fit: contain;
}
@media (min-width:514px){
   
}
@media (min-width:768px) {
    .logoContainer{
        height: 10rem;
    }
  
}
@media (min-width:1080px) {
    .logoContainer{
        height: 12rem;
    }
   
}
@media (min-width:1440px) {
    
    /* .logoContainer{
        height: 13rem;
    } */
}
@media (min-width:1920px) {}