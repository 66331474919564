.navigationLinkBoxContainer{
    display: flex;
    flex-direction: column;
  /* margin: 1rem 0; */
    /* margin-right: 2rem; */
    /* row-gap: 2rem; */
    /* background-color: aqua; */
}
.navigationLinkBoxContainer a{
    color: var(--color-gray-02);
    font-size: 2rem;
    /* font-size: var(--text-size-02); */
    /* font-size: var(--nav-link-font-size); */

}