.templateWrapper {
    padding: 10rem 0 4rem 0;
    /* min-height: 100rem; */
    background-color: var(--color-gray-06);
}



.textGroup {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    gap: .5rem;
}

.titleText {
    color: black;
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
}

.subTitleText {
    font-size: var(--text-size-02);
    color: black;
    font-family: var(--slim-title-font-family);
    border-bottom: 1px solid var(--color-gray-04);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.sectionTitleText {
    font-size: var(--text-size-02);
    color: black;
    font-family: var(--semiBold-title-font-family);
    margin-bottom: .5rem;
    margin-top: 4rem;
    
}

.sectionSubtitleText {
    font-size: var(--text-size-01);
    color: var(--color-gray-03);
    font-family: var(--slim-title-font-family);
    margin-bottom: 1rem;
}

.textGroupLabel {
    color: var(--color-gray-03);
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
}

.textGroupInfo {
    color: var(--color-red-03);
    font-size: var(--text-size-01);
    font-family: var(--semiBold-title-font-family);
}

.buttonBox{
    display: inline-block;
}
@media (min-width:1080px) {
    .templateWrapper {
        min-height: 100rem;
    }
   
}
/* .updateProfileButtonBox{
    margin-bottom: 3rem;
} */

/*  */







/* @media (min-width:514px) {
 

}

@media (min-width:768px) {
 
}

@media (min-width:1080px) {
   
}

@media (min-width:1440px) {}

@media (min-width:1920px) {} */