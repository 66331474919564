.navLinksListContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* row-gap: 1rem; */
  /* margin: 1rem; */
  /* column-gap: 3rem; */
}

.navLinksListContainer a, li {
  /* display: inline; */
  list-style: none;
  font-size: var(--text-size-02);  
  color: black;
  /* font-weight: 100; */
}
/* .navLinksListContainer li:hover,
.navLinksListContainer li:active
{
  border-bottom: 2px solid var(--color-red-03);
}
.navLinksListContainer a {
  text-decoration: none;
  color: var(--color-gray-01);
  font-size: 1.7rem;  

}
.navLinksListContainer a:hover,
.navLinksListContainer a:active
{
  border-bottom: 2px solid var(--color-red-03);
}
.active {
  border-bottom: 2px solid var(--color-red-03);
} */
/*  */
/* ----------------------DROPDOWN */

@media (min-width:1440px) {
  .navLinksListContainer{
    display: flex;
    flex-direction: column;
  }


}