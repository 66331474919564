.messageWrapper{
    /* display: flex;
    flex-direction: row; */
    background-color: var(--color-gray-05);
    border-radius: 14px;
    padding: 2rem 1rem;
    margin: 1rem 0;
    justify-content: space-between;
    align-items: center;
}
.flashMessageContainer{
    background-color: var(--color-gray-05);
    border-radius: 16px;
    padding: 1.2rem;
    margin-top: 2rem;
}

.messageTextBox{
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    column-gap: 1rem;
}
.messageText{
    font-size: var(--body-font-size);
}
.successIcon svg{
    font-size: var(--body-font-size);
    color: green;
    display: flex;
}