
.signInBackgroundImageWrapper{
    background-color: var(--color-gray-06);
    display: grid;
    /* align-items: center; */
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:  15rem 1fr 20rem;
}
.signUpBackgroundImageWrapper{
    background-color: var(--color-gray-06);
    display: grid;
    /* align-items: center; */
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:  8rem 1fr 5rem;
}

.resetPasswordBackgroundImageWrapper{
    background-color: var(--color-gray-06);
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:  8rem 1fr 5rem;
}

.forgotPasswordBackgroundImageWrapper{
    background-color: var(--color-gray-05);
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows:  8rem 1fr 5rem;
}

.pageContainer {
    margin-top: 2rem;
    grid-column: 1/-1;
    grid-row: 2;
  
}


.formContainer {
    display: flex;
    flex-direction: column;
    /* background-color: rgba(117, 114, 114, 0.5);  */
    max-width: 30rem;
    padding: 2rem;
    margin: 0 auto;
    margin-top: 3rem;
    border-radius: 16px;
}

.titleText {
    color: black;
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
    padding-bottom: 1rem;
}
.subtitleText {
    color: black;
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
    /* border-bottom: 1px solid var(--color-gray-04); */
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.formLinksBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}
.formLinksBox a{
    color: var(--color-gray-03);
}
.footerTextWrapper {
    margin-top: 1rem;
}

.footerText {
    font-size: var(--text-size-00);
    color: var(--color-gray-02);
}

.footerText a {
    color: var(--color-red-03);
    font-family: var(--semiBold-title-font-family);
    text-decoration: none;
}

@media (min-width:514px){

}


@media (min-width:768px){

    .signInBackgroundImageWrapper{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: 
        linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../Theme/Product/SubcategoryImages/laundry-23.webp');
/*        
        grid-template-rows:  20rem 1fr 10rem; */
    }
    .signUpBackgroundImageWrapper{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: 
        linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../Theme/Product/SubcategoryImages/fridge-13.webp');
        grid-template-rows:  10rem 1fr 10rem;
    }
    .resetPasswordBackgroundImageWrapper{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: 
        linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../Theme/Product/SubcategoryImages/fridge-13.webp');
        grid-template-rows:  10rem 1fr 10rem;
    }
    .forgotPasswordBackgroundImageWrapper{
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-image: 
        linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../Theme/Product/SubcategoryImages/fridge-13.webp');
        grid-template-rows:  10rem 1fr 10rem;
    }
   
    
    
    
    .formContainer {
        background-color: var(--color-gray-06);
        max-width: 60rem;
        padding: 2rem 5rem 2rem 5rem;
        margin-top: 8rem;
        border-radius: 16px;
    }
    
   
    .footerTextWrapper {
        margin-top: 3rem;
    }
    
   
    
}
@media (min-width:1080px){
    .signInBackgroundImageWrapper{
       
        /* grid-template-rows:  1rem 100rem 10rem; */
        grid-template-rows:  10vh 100vh 10vh;
        background-attachment: fixed;
    }
    .signUpBackgroundImageWrapper{
               /* grid-template-rows:  1rem 100rem 10rem; */
               grid-template-rows:  10vh 100vh 10vh;
    }
    .formContainer {
        padding: 2rem 5rem 2rem 5rem;
        margin-top: 5rem;
        border-radius: 16px;
    }

}
@media (min-width:1440px) {
   
    
    .signInBackgroundImageWrapper{
        height: max-content;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        /* grid-template-rows: 1rem 100rem auto; */
        grid-template-rows: auto 100vh auto;
        background-attachment: fixed;
    }
    .signUpBackgroundImageWrapper{
        height: max-content;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        /* grid-template-rows: 10rem 1fr auto; */
        /* grid-template-rows: 1rem 100rem auto; */
        grid-template-rows: 15vh 80vh 10vh;
    }
    
    .resetPasswordBackgroundImageWrapper{
        height: max-content;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        /* grid-template-rows: 10rem 1fr auto; */
        grid-template-rows: auto 100vh auto;
    }
    
    .forgotPasswordBackgroundImageWrapper{
        height: max-content;
        display: grid;
        align-items: center;
        grid-template-columns: repeat(12, 1fr);
        /* grid-template-rows: 10rem 1fr auto; */
        grid-template-rows: auto 100vh auto;
    }
    /* .pageContainer {
        grid-row: 2;
        grid-column: 1/-1;
    } */
    
    .formContainer {
        /* display: flex;
        flex-direction: column; */
        /* background-color: var(--color-gray-07); */
        /* max-width: 80rem; */
        grid-row: 2;
        grid-column: 1/-1;
        padding: 2rem;
        margin-top: 10rem;
        margin-bottom: 10rem;
        border-radius: 16px;
        margin-left: 38rem;
    }
    
  
    
    .footerTextWrapper {
        margin-top: 3rem;
    }
    
  
  
}

@media (min-width:1920px) {}