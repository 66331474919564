

.mainFooterContainer {
    background-color: var(--color-gray-07);
    height: auto;
    border-top: 1px solid var(--color-gray-04);
    padding: 4rem 0rem;
    /* border-top: 1px solid var(--color-gray-05);
    border-bottom: 1px solid var(--color-gray-05); */
}

.container {
    /* padding: 2rem; */
    display: flex;
    flex-direction: column;
    /* padding: 2rem; */
}

.containerItem {
    /* margin: .5rem 0; */
    display: inline-flex;
    /* gap: 1rem; */
    flex-direction: column;
}

.footerLogoLanguageWrapper {
    display: flex;
    flex-direction: column;
    max-width: 180rem;
    margin: 0 auto;
    /* margin-bottom: -5rem; */
}

.languageSelectionContainer {
    display: flex;
    /* margin-top: 4rem; */
    background-color: var(--color-gray-07);
    padding: 6rem 8rem 0 2rem;
}

.logoBox {
    height: auto;
    /* width: 20rem; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* padding: 2rem 2rem 3rem 2rem; */
    padding: 0 0 0 0;

}

.logoBox img {
    height: 100%;
    object-fit: contain;
}

.footerTitle {
    /* margin-top: 2rem; */
    padding-top: 2rem;
    color: black;
    font-size: var(--text-size-01);
    font-family: var(--semiBold-title-font-family);

    /* border-top: 1px solid var(--color-gray-06); */
}


.footerCol {
    display: flex;
    flex-direction: column;
    /* column-gap: 2.5rem; */
    margin-top: .8rem;
    width: fit-content;
    font-size: var(--text-size-00);
}

.footerCol a {
    text-decoration: none;
    display: inline-flex;
    color: var(--color-gray-03);
    font-size: var(--text-size-00);
    margin: .3rem 0;
}
.externalTranLink > a{
    color: var(--color-gray-03);
    font-size:1.8rem;
}
.footerRightsBoxWrapper {
    background-color: var(--color-gray-06);
    border-top: 1px solid var(--color-gray-04);
}

.footerRightsBox {
    color: var(--color-gray-02);
    padding: 2rem 0;
    /* margin-top: 1rem; */
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    /* border-top: .1rem solid gainsboro; */

}

.footerRightsBox p {
    font-size: var(--text-size-00);
    color: var(--color-gray-03);
}


.active {
    border-bottom: 2px solid var(--color-red-03);
}

.linkText {
    display: inline-block;
}

@media (min-width: 768px) {
    .mainFooterContainer {
        padding: 4rem 0;
    }

    /* .container {
        padding: 2rem 4rem;
    } */

    .footerLogoBox {
        /* padding: 0 2rem; */
    }

    .languageSelectionContainer {

        padding: 6rem 8rem 0 4rem;
    }

    .footerRightsBox {
        padding: 2rem;
    }
  
}


@media (min-width:1080px) {
    .footerLogoBox {
        /* padding: 0rem 8rem 0 6rem; */
        /* border-top: 1px solid var(--color-gray-06); */
        /* padding-top: 4rem; */
        /* padding-bottom: 4rem; */
    }

    .logoBox {
        height: 13rem;
        display: flex;
        /* border-bottom: 1px solid var(--color-gray-04); */

    }

    .footerTitle {
        margin-top: 0rem;
        padding-top: 0;
        /* padding-top: 2rem;
        padding-bottom: 0rem; */
        font-size: 2rem;
    }

    /* .container {
        padding: 2rem 8rem;
    } */

    .container {
        max-width: 180rem;
        margin: 0 auto;
        /* padding: 2rem 8rem; */
        display: grid;
        column-gap: 2rem;
        grid-template-columns: repeat(5, 1fr);
        padding-bottom: 4rem;

    }

    .footerCol a {

        /* font-size: 1.8rem; */
        font-size: 2rem;
        margin: .3rem 0;
        transform: scale(1);
        transition: transform 0.3s ease, border-color 0.3s ease;
        border-bottom: 2px solid transparent;

    }

    .footerCol a:hover,
    
    .footerCol a:active{
        border-bottom: 2px solid var(--color-red-03);
        transform: scale(1);
        /* border-bottom-color: var(--color-red-03); */
    }
    .externalTranLink > a{
        font-size:2rem;
    }

    .footerRightsBox {
        max-width: 180rem;
        margin: 0 auto;
        padding: 2rem 0;

    }

    .languageSelectionContainer {

        padding: 4rem 8rem 0 8rem;
    }
}

@media (min-width: 1440px) {
    .container {
        /* padding: 0rem 4rem 4rem 8rem; */
        display: grid;
        column-gap: 0rem;
        grid-template-columns: repeat(5, 1fr);


    }
}

@media (min-width: 1920px) {}

@media print {
    .mainFooterContainer {
        display: none;
    }
    .footerRightsBoxWrapper{
        display: none;
    }
}