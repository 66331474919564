.buttonBox {
    display: flex;
    margin-top: 2rem;
}
.buttonBox button:hover{
    cursor: pointer;
}

.passwordResetBox {
    /* margin-top:2rem; */
    margin-bottom: 1rem;
}

.passwordResetBox a {
    text-decoration: none;
}

.passwordResetText {
    font-size: var(--text-size-00);
    color: var(--color-gray-01);
}

.formRow {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
}
.formCheckRow{
    display: flex;
    flex-direction: row;
    gap:1rem;
    justify-content: space-between;
    align-items: center;
}
.formCheckRow input{
    height: 2rem;
    width: 2rem;
}

.termsLabelText{
    font-size: var(--text-size-00);
    color: var(--color-gray-03);
}