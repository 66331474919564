/* TABLE STYLES */
.tableWrapper {
    overflow-x: scroll;
    max-width: 100%;
    scrollbar-width: thin;
    scrollbar-color: #555 #F5F5F5;
}
.table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;

}

.columnHeader{
    background-color: var(--color-gray-01);
  }
.tableRows th{
    padding: 1rem;
    font-size: var(--text-size-00);
    font-family: var(--slim-title-font-family);
    font-weight: normal;
    color: white;
    text-align: center;
}
  .tableData{
    text-align: center;
    padding: .2rem 1rem ;
    font-size: var(--text-size-00);
    font-family: var(--slim-title-font-family);
}

.tableWrapper::-webkit-scrollbar {
    width: 12px;
    /* Adjust the width as you like */
    background-color: #F5F5F5;
    /* Color of the scrollbar track */
}

.tableWrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* Optional: to make the corners round */
    background-color: #555;
    /* Color of the draggable part */
    border: 2px solid #F5F5F5;
    /* Same color as the track to create spacing effect */
}

.tableWrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* Optional: to make the corners round */
    background-color: #555;
    /* Color of the draggable part */
    border: 2px solid #F5F5F5;
    /* Same color as the track to create spacing effect */
}





tr:nth-child(even) {
    background-color: #f2f2f2
}

.tableBody tr:hover {
    background-color: var(--color-gray-05);
    color: var(--color-gray-02);
}


@media (min-width:514px) {
  
}
@media (min-width:768px) {
    .tableData{
        text-align: center;
        padding: 1rem;
      
    }
}
@media (min-width:1080px) {
   
}
@media (min-width:1440px) {}
@media (min-width:1920px) {}