.dataGraphRowContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: white; */
    /* padding: 2rem; */
    margin: 0 0 2rem 0;
    border-radius: 16px;
  

}
.dataGraphRowTitle{
    font-size: var(--subtitle-font-size);
    color: var(--color-gray-01);
}
.dataGraphRowSubtitle{
    font-size: var(--body-font-size);
    color: var(--color-gray-02);
}

.dataGraphRowWrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    column-gap: 2rem;
}
@media (min-width: 514px) {}

@media (min-width: 768px) {}

@media (min-width: 1440px) {}
@media (min-width: 1920px) {}