.resourcesSectionWrapper{
    background-color: var(--color-gray-06);
    /* padding: 2rem; */
    padding: 2rem 0 2rem 0;
    border-top: 1px solid var(--color-gray-04);
    /* border-bottom: 1px solid var(--color-gray-04); */
}
/* .resourcesContainer {
    max-width: 180rem;
    margin: 0 auto;
} */

.titleBox {
    margin-bottom: 2rem;
}

.titleText {
    font-size: var(--text-size-02);
    /* line-height: 1.8; */
    color: var(--color-gray-02);
    font-family: var(--semiBold-title-font-family);
}

.resourceLinksWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 2rem;
}
.resourceLinksWrapper button {
    color: var(--color-gray-03);
}
.resourceLinksWrapper button svg {
    margin-left: .5rem;
    color: var(--color-gray-03);
}
.resourceLinksWrapper a {
    text-decoration: none;
}
@media (min-width: 768px) {
   
   
}

@media (min-width: 1080px) {
    .resourcesSectionWrapper{
 
        padding: 4rem 0;
    
    }

}
@media (min-width: 1440px) {
   
}
@media (min-width: 1810px) {
  
}
@media (min-width: 1920px) {}