.clockText {
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03)
}

@media (min-width: 514px) {}

@media (min-width: 768px) {}

@media (min-width: 1080px) {}

@media (min-width: 1440px) {}

@media (min-width: 1920px) {}