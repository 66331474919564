.videosSectionWrapper{
    /* border-top: 1px solid var(--color-gray-04);
    background-color: var(--color-gray-06); */
    padding: 2rem 2rem 0 2rem;
}
.videosContainer {
    max-width: 160rem;
    margin: 0 auto;
}

.titleText {
    font-size: var(--text-size-02);
    color: var(--color-gray-02);
    font-family: var(--semiBold-title-font-family);
}

.titleBox {
    margin-bottom: 1rem;
}

.mobileVideoHeadWrapper {
    display: flex;
    flex-direction: column;
    /* padding: 0 2rem; */
}

.arrowBox {
    display: inline-flex;
    flex-direction: row;
    column-gap: 2rem;
}

.mobileWrapperScroll {
    display: flex;
    padding: 2rem 0rem 0 0rem;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    column-gap: 1rem;
}


.mobileWrapperScroll::-webkit-scrollbar {
    width: 0;
    background: transparent;
}
.desktopVideoHeadWrapper {
    display: none;
}

.desktopWrapperScroll {
    display: none;
}

@media (min-width: 768px) {
 .videosSectionWrapper{
    padding: 2rem 4rem 0 4rem;
 }
    .mobileVideoHeadWrapper {
        display: none;
    }

    .mobileWrapperScroll {
        display: none;
    }

    .desktopVideoHeadWrapper {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        /* padding: 0 2rem; */
    }

    .desktopWrapperScroll {
        display: flex;
      
        padding-top: 2rem;
       
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        column-gap: 2rem;
        /* border-top: 1px solid var(--color-gray-04); */
        /* border-bottom: 1px solid var(--color-gray-04); */
    }
   .desktopWrapperScroll::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

}
@media (min-width: 1080px) {
    .videosSectionWrapper {
        padding: 4rem 4rem 0 4rem;
        /* margin: 4rem 8rem 0rem 8rem; */
     }
}
@media (min-width: 1440px) {
    .desktopVideoHeadWrapper {
      
        /* padding: 0 4rem; */
    }
    
}
@media (min-width: 1810px) {
   .desktopVideoHeadWrapper{
    padding: 0;
   }
}

@media (min-width: 1920px) {}