.statBlockContainer {
    display: flex;
      background-color: white;
    
      padding: 2rem;
      border-radius: 16px;
      /* margin: 1rem; */
      width: 100%; /* On mobile, take the full width of the container */
     /* min-height: 12rem;
     max-height: 20rem; */
     height: 10rem;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .textBox {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: space-between;
  }
  
  .title {
      font-size: var(--text-size-01);
      font-family: var(--slim-title-font-family);
      color: black;
  }
  
  .data {
      font-size: var(--text-size-01);
      color: var(--color-red-02);
  }
  
  @media (min-width: 514px) {
       .statBlockContainer{
          height: 10rem;
      }
  }
  
  @media (min-width: 768px) {
      /* .statBlockContainer{
          height: 14rem;
      } */
  }
  
  @media (min-width: 1080px) {
      /* .statBlockContainer{
          height: 15rem;
      } */
  }
  
  @media (min-width: 1440px) {
      .statBlockContainer{
          height: 10rem;
      }
  }
  
  @media (min-width: 1920px) {}