@media print {
    body {
        margin: 0;
        color: #000;
        background-color: #fff;
      }
    
    .coverPage {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* margin-top: 20rem; */
        max-width: 100rem;
        margin: 20rem auto 0 auto;

        /* max-width: 100rem; */
        /* min-height: 100vh; */
        /* page-break-after: always;  */
    }
    .productItem{
        page-break-inside :  avoid;
    }

    .coverTitleText {
        font-size: 6rem;
        font-family: var(--slim-title-font-family);
        padding-bottom: 1rem;
       
    }

    .subtitleText {
        font-size: 4rem;
        color: var(--color-gray-03);
        font-family: var(--slim-title-font-family);
    }

    .descriptionText {
        font-size: 2rem;
        font-family: var(--slim-title-font-family);
    }

    .printScreenContainer {
        background-color: white;
        /* page-break-inside: avoid-page; */
        margin: 0 auto;
        /* height: 100vh; */
        /* max-width: 240rem;
        padding: 2rem;
        page-break-inside: avoid; */

    }
    .productContainer{
        page-break-inside: avoid; 
        page-break-before: always;
        page-break-after: always;
        height: 100vh;
    }

    .logoBox img {
        height: 40rem;
       
        /* margin-left: -3rem; */
    }
}