.checkboxRow {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  /* margin-bottom: 2rem;   */
  border-bottom: 1px solid var(--color-gray-04);
  column-gap: 1rem;
}
@media (min-width: 768px) {}


@media (min-width: 1080px) {}



.checkboxRow {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  /* margin-bottom: 2rem;   */
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-gray-04);
}

.checkboxLabel {
  font-size: var(--text-size-01);

  font-family: var(--slim-title-font-family);
  color: black;
  margin-right: auto; /* Adds some space between the label and the checkbox */
}
.checkboxInput{
  margin-left: auto;
}
.checkboxRow label:hover{
  cursor: pointer;
}

.checkboxRow input[type="checkbox"] {
  border: none;
  min-width: 2rem;
  max-width: 2rem;
  height: 3rem;
  border-radius: 8px;
  border-bottom: 3px solid white;
  /* font-size: var(--body-font-size); */
  padding: 0.8rem 1rem; /* Auto width for checkboxes */
}
.checkboxRow input[type="checkbox"]:hover{
  cursor: pointer;
}

.requiredAsterix {
  color: var(--color-red-03);
}

/* Styles for tablets and larger devices */
@media (min-width: 768px) {
 
  .checkboxRow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 1rem;
    /* padding-bottom: 2rem; */
  }
  
}

/* Styles for laptops and larger devices */
@media (min-width: 1440px) {
 
}

/* Styles for larger desktop screens */
@media (min-width: 1920px) {
 
}
