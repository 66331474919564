.qrCodeImageContainer{
    page-break-inside: avoid;

    border-bottom: 1px solid var(--color-gray-03);
    padding-bottom: 1rem;
}
.qrCodeSectionWrapper{
    display: flex;
    flex-direction: row;
    gap: 4rem;
}
.title{
    font-size: 1.2rem;
    font-family: var(--semiBold-title-font-family);
    /* margin-top: 1rem; */
    /* margin-bottom: 1rem; */
}

.itemText{
    font-size: 1.2rem;
    font-family: var(--slim-title-font-family); 
    /* margin-bottom: 1rem; */
}
.productImageContainer{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid black; */
    /* border-radius: 16px; */
}
.productImageContainer img{
    max-width: 8rem;
    height: 100%;
    object-fit: contain;
    /* margin-left: -4rem; */
}

.imageItem{
    display: flex;
    flex-direction: column;
    /* margin-right: 4rem; */
    text-align: left;
}
.imageWrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}