/* .legalBackground{
    background-color: var(--color-gray-06);
    margin-top: 8rem;
} */
.sectionContainer{
    display: flex;
    flex-direction: column;
    /* padding: 0 2rem; */
    margin: 0 0 4rem 0;
    font-family: var(--slim-title-font-family);
 
}
.sectionGroup{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.titleBox{
 
}
.textBox{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.listBox{
  
    padding-left: 1rem;
}

.groupWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
}
.nestedGroupWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
    padding-left: 1rem;
}
.listTitleBox{

}


.title{
    font-size: var(--text-size-02);
    font-family: var(--semiBold-title-font-family);
}
.nestedTitle{
    font-size: var(--text-size-01);
    font-family: var(--semiBold-title-font-family);
}
.listTitle{
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    /* color: var(--color-gray-03); */
}
.bodyText{
    font-size: var(--text-size-01);
  /* color: var(--color-gray-03); */
}

.listWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.listItem {
    font-size: var(--text-size-01);
    color: black;
    list-style: disc;
    list-style-position: inside;

}







@media (min-width:514px) {
  /* .sectionContainer{
    padding: 0 2rem;
  } */
}
@media (min-width:768px) {
    
}
@media (min-width: 1080px) {
    .sectionContainer{
        padding: 0 4rem;
      }
}
@media (min-width: 1440px) {
    .imageBox {
        margin-top: 4rem;
        border-radius: 16px;
    }
}
@media (min-width: 1830px) {
    .sectionContainer{
        padding: 0;
      }
}
