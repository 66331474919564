/* ::-webkit-scrollbar {
    width: 0;
    background: transparent;
} */

.container {
    color: white;
    position: relative;
    /* border-top: 1px solid var(--color-gray-04); */
}

.leftArrow,
.rightArrow {
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 100;
    cursor: pointer;
}

/* .leftArrow {
    left: -5rem;
}

.rightArrow {
    right: -5rem;
} */

.leftArrow span,
.rightArrow span {
    color: var(--color-primary-dark);
    font-size: 5rem;
}

.wrapperScroll {
    display: flex;
    margin-top: 0rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 4rem;

    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    column-gap: 2rem;
    border-bottom: 1px solid var(--color-gray-04);
}

.wrapperScroll::-webkit-scrollbar {
    width: 12px;
}

.wrapperScroll::-webkit-scrollbar-track {
    background: var(--color-gray-05);
}

.wrapperScroll::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-04);
    border-radius: 10px;
    border: 3px solid var(--color-gray-05);
}

.wrapperScroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-red-02);
}

/* .wrapperScroller div:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-05);
} */
.card {
    flex: 0 0 100%;
    max-width: 100%;
    height: max-content;
    margin-right: 1rem;
    /* padding: 0 1rem; */
    /* box-sizing: border-box; */
}

.arrowBox {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 100%;
    /* margin-bottom: 1rem;
    padding-bottom: 1rem; */
}

@media (min-width: 390px) {
    .card {
        flex: 0 0 100%;
        max-width: min-content;
        /* max-width: 50%; */
    }
}

@media (min-width: 514px) {

    /* .card {
        flex: 0 0 50%; 
        max-width: 50%;
    }  */
    .arrowBox {
        margin-bottom: 1rem;
    }

}

@media (min-width: 768px) {

    /* .card {
        flex: 0 0 50%;
        max-width: 50%;
    } */
    .wrapperScroll {
        display: flex;
        /* padding-top: 1rem;
        padding-bottom: 2rem; */
        /* margin-top: 0.75rem; */
        margin-top: 0;
        /* margin-top: 2rem; */
        padding-top: 0rem;
        padding-bottom: 4rem;
        margin-bottom: 4rem;
        /* padding: 4rem; */
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        column-gap: 2rem;
        /* border-top: 1px solid var(--color-gray-04); */
        /* border-bottom: 1px solid var(--color-gray-04); */

    }

}

/* @media (min-width: 1080px) {
    
    .card {

        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    
} */
@media (min-width: 1280px) {

    /* .card {

        flex: 0 0 33.33%; 
        max-width: 33.33%;
    } */

}

@media (min-width: 1440px) {}

@media (min-width: 1810px) {}

@media (min-width: 1920px) {

    /* .card {
        flex: 0 0 25%; 
        max-width: 25%;
    } */
}