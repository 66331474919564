.bodyContainer{
    /* background-color: black; */
    /* margin: 0 2rem; */
    margin:  0 2rem;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
@media (min-width:514px) {
    
}
@media (min-width:768px) {}
@media (min-width:1080px) {
    .bodyContainer{
        /* margin: 2rem 8rem 8rem 8rem;   */
        max-width: 160rem;
        margin: 0 auto;
        /* padding: 2rem; */
    }
}
@media (min-width:1440px) {}
@media (min-width:1920px) {

}