.pageWrapperTemplateContainer{
    /* margin: 8rem 0 0 0; */
    /* margin: 18rem 0 0 0; */
    display: grid;
    /* grid-template-rows: 13rem auto auto; */
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
 
}

.pageBodyWrapper{}
@media (min-width:514px) {
  
}
@media (min-width:768px) {
    .pageWrapperTemplateContainer{
        /* background-color: blue; */
        /* margin: 14rem 2rem 2rem 2rem; */
    }
}
@media (min-width:1080px) {
      .pageWrapperTemplateContainer{
        /* margin: 18rem 0 0 0; */
        /* margin: 13rem 0 0 0; */
    }
}
@media (min-width:1280px) {
    .pageWrapperTemplateContainer{
      /* margin: 8rem; */
  }
}
@media (min-width:1440px) {}
@media (min-width:1920px) {}