.templateWrapper{
    margin-top: 9rem;
}
.mainProductDetailsContainer{
    /* margin-top: 15rem; */
    margin-top: 9rem;
}
.productBannerGridContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0 2rem 0;
    /* margin-top: 3rem; */
}

.productAboveFoldContainer {
    display: flex;
    flex-direction: column;
    /* gap: 4rem; */
    justify-content: space-between;

    /* margin: 0 2rem; //NEW */
    
    /* justify-content: flex-start; */
    /* margin: 5rem 2rem 3rem 2rem;
    padding: 4rem 0 0 0;
    padding: 0 2rem; */

}
.specsBox{
    background-color: var(--color-gray-06);
    border-top: 1px solid var(--color-gray-04);
}
.logosBox{
    background-color: var(--color-gray-06);
    /* border-top: 1px solid var(--color-gray-05); */
}
.backgroundBlend{
    background-color: var(--color-gray-04);
}
.matchingProductBox{
    background-color: var(--color-gray-06);
    border-top: 1px solid var(--color-gray-04);
    padding-top: 3rem;
}

@media (min-width: 768px) {
.mainProductDetailsContainer{
    margin-top: 12rem;
}
    .productAboveFoldContainer {
        flex-direction: row;
        align-items: center;    
        /* margin: 0rem 4rem 4rem 4rem; */
        margin: 4rem 0 4rem 0;
        gap: 4rem;
    }
}
@media (min-width: 1080px){
    .templateWrapper{
        margin-top: 11rem;
    }
    .productAboveFoldContainer {
        margin-top: 11rem;
    }
}
@media (min-width: 1440px) {
    /* .productBackgroundBannerContainer {
        height: 50vh;
     
    } */

    .productAboveFoldContainer {
        /* margin: 8rem; */
        /* padding: 0rem 4rem 4rem 4rem; */
    }
}
@media (min-width: 1830px) {
    .productAboveFoldContainer {
        /* margin: 8rem; */
        /* padding: 0 0 4rem 0 ; */
    }
}
@media (min-width: 1920px) {
  

 
    .productHeadGroupWrapper {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .productAboveFoldContainer {
        /* margin: 8rem; */
    }

    .colorLegenBox {
        margin-top: 10rem;
    }
}