.productCardContainer {
  background-color: white;
  border: 1px solid var(--color-gray-04);
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 14px;
  z-index: 100;
  margin-top: 1rem;
}

.productCardTextBox {
  font-family: var(--slim-title-font-family);
}

.productCardImageBox {
  height: auto;
  width: 100%;
  padding: 2rem 3rem 0rem 3rem;
  display: flex;
  justify-content: center;
}

.productCardImageBox img {
  max-width: 100%;
  height: 13rem;
  object-fit: contain;
}



.productBodyWrapper {

  margin: 0 2rem 2rem 2rem;
}

.productCardButtonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
}

.productCardButtonBox a {
  text-decoration: none;
}


.productTitle {
  /* color: var(--color-primary-red); */

  color: var(--color-gray-01);
  /* font-family: var(--title-font-family); */
  font-size: var(--text-size-00);
  font-family: var(--slim-title-font-family);
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
}

.productSubtitle {
  color: black;
  font-size: var(--text-size-00);
  padding-bottom: 1rem;
}


.productSubtitle {
  color: var(--color-gray-02);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clampedSubtitle {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.seeDetails {
  display: inline-block;
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.categoryCardContainer {
  background-color: white;
}


.retailerLogo{
padding: 2rem 0 0 2rem;
}
.availabilityText{
  font-size: 1.4rem;
  color: var(--color-gray-03);
}
.availabilityTextHidden{
  font-size: var(--text-size-00);
  color: transparent;

}


@media (min-width: 768px) {

  .cardImageWrapper {
    height: auto;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

}

