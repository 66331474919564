/* -----------------------------------------*/
/* FEATURE DEFINITIONS */
/* -----------------------------------------*/
.definitionsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem 0;
    
}

.productTypeHeader {
    font-size: var(--text-size-02);
    display: flex;
    align-items: center;
    /* column-gap: 2rem; */
    padding: 2rem;
    background-color: var(--color-gray-06);
    border: 1px solid var(--color-gray-05);
    border-radius: 16px;
}

/* ALSO USED IN WARRANTIES */
.productTypeText { 
    color: black;
    font-family: var(--semiBold-title-font-family);
    font-size: var(--text-size-02);
}

.productTypeHeader svg {
    margin-left: auto;
    margin-right: 2rem;
    font-size: var(--text-size-02);
}

.productTypeHeader svg:hover{
    cursor: pointer;
}

.featureDefinitionsRow {
    display: block;
    font-size: var(--text-size-01);
    margin: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-gray-04);
}

.featureTitleText {
    font-family: var(--semiBold-title-font-family);
    color: var(--color-gray-01);
    font-size: var(--text-size-01);
    margin-bottom: 1rem;
}

.definitionText {
    color: var(--color-gray-03);
}

/* -----------------------------------------*/
/* MODEL TRANSITION */
/* -----------------------------------------*/
.transitionImgWrapper {
    /* height: 100%; */
    /* width: 160rem; */
    display: flex;
    justify-content: center;
    /* background-color: var(--color-gray-06); */
}

.transitionImgWrapper img {
    width: 100%;
    object-fit: contain;
    height: auto;
}

/* -----------------------------------------*/
/* LIMITED WARRANTIES */
/* -----------------------------------------*/

.warrantyContainer {
    /* grid-row: 3;
    grid-column: 1/-1;
   */  
   /* margin: 0 2rem 5rem 2rem; */
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    row-gap: 2rem;
    column-gap: 3rem;
}
.warrantyItem{
    background-color: var(--color-gray-06);
    display: flex;
    flex-direction: column;
    /* margin: 1rem 0;
   */ 
   padding: 2rem; 
    border-radius: 16px;
} 
.itemList{
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    row-gap: .8rem;
}
.itemList li{
    list-style: none;
    font-size: var(--text-size-01);
    color: var(--color-gray-03);
    /* line-height: 2; */
    /* list-style-position: inside; */
}



@media (min-width:514px) {
    .definitionsWrapper{
        padding: 0 0 10rem 0;
    }
    .warrantyContainer{
        padding: 0 0 10rem 0;
    }
}

@media (min-width:768px) {
    .definitionsWrapper{
        padding: 0 4rem 10rem 4rem;
    }
    .warrantyContainer{
        padding: 0 2rem 10rem 2rem;
    }
}

@media (min-width:1080px) {
    .warrantyContainer {
        /* margin: 0 8rem 5rem 8rem ; */
        gap: 2rem;
       
    }
}

@media (min-width:1440px) {}
@media (min-width:1810px) {
    .definitionsWrapper, .warrantyContainer{
        padding: 0 0 10rem 0;
    }
}

@media (min-width:1920px) {}