.sectionTextContainer {
    height: auto;
    width: 100%;
    color: var(--color-gray-01);
    padding: 2rem 0  ;
    /* margin-top: 2rem; */
    /* padding-right: 70rem; */
}

.titleText {
    font-family: var(--semiBold-title-font-family);
    font-size: var(--text-size-05);
    color: black;
    margin-bottom: 1rem;
}
.topTitleText{
    /* text-transform: uppercase; */
    font-size: var(--text-size-01);
}
.subtitleText {
    font-size: var(--text-size-00);
   
    color: var(--color-gray-03);
   
}
.mainTitleText{
    font-size: var(--text-size-05);
    color: black;
    margin-bottom: 2rem;
}

.headlineText {
    font-size: var(--text-size-03);
}
.descriptionText {
    font-size: var(--text-size-01);
    color: var(--color-gray-03);
    /* line-height: 4rem; */
}
.textBox p:not(:last-child){
    margin-bottom: 1rem;
}


.buttonContainer{
    margin-top: 2rem;
}
@media (min-width:514px) {
    
}
@media (min-width:768px) {
    .sectionTextContainer {
        padding: 2rem 0;
        /* padding: 6rem 0; */
        /* padding-right: 15rem; */
        /* width: 70%; */
    }
  
}
@media (min-width:1080px) {
    .sectionTextContainer {
       
        /* padding: 4rem 50rem 4rem 4rem; */
        /* padding: 4rem 8rem 2rem 8rem; */
        /* width: 50%;        */
        /* max-width: 180rem;
        margin: 0 auto; */
        /* padding-right: 40rem; */
    }
   
}
@media (min-width:1280px) {
    /* .sectionTextContainer {
     
        padding: 4rem 50rem 4rem 4rem;
    } */
}
@media (min-width:1440px) {
    /* .sectionTextContainer {
     
        padding: 4rem 50rem 4rem 4rem;
    } */
}

@media (min-width:1810px) {
    /* .sectionTextContainer {
        padding: 4rem 50rem 4rem 2rem;
    } */
}
@media (min-width:1920px) {
    /* .sectionTextContainer {
        padding: 4rem 50rem 4rem 0rem;
    } */
}

