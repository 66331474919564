.leaderBoardTableContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    background-color: var(--color-gray-06);
    border-radius: 16px;
}

.textBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.title {
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
    color: black;
}

.subtitle {
    font-size: var(--text-size-03);
    font-family: var(--slim-title-font-family);
    /* color: var(--color-gray-02); */
}
.rank{
    font-size: var(--text-size-01);
  
}
.rankNumber{
    font-size: var(--text-size-01);
    color: var(--color-red-02);
    margin-left:1rem; 
}
.leaderPoint{
    font-size: var(--text-size-01);
    color: var(--color-gray-01);
}
.leaderBoardTableWrapper{
    /* background-color: var(--color-gray-06); */
    background-color: white;
    padding: 2rem;
    border-radius: 16px;
      /* margin-top: 2rem; */
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
      /* margin-bottom: 2rem; */
}


/* FORMATTER / LEADERROW */
.leaderRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-05);
   
    gap: 1rem;
    margin: 1rem 0;
    /* padding: 1rem; */
    /* margin: 2rem; */
    /* background-color: white; */
    /* border-radius: 16px; */
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1); */
}

@media (min-width: 514px) {
    .leaderRow {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-bottom: 1px solid var(--color-gray-05);
        padding-bottom: 1rem;
        align-items: center;
    }
}
@media (min-width: 768px) {}

@media (min-width: 1080px) {
  
}

@media (min-width: 1440px) {}

@media (min-width: 1920px) {}