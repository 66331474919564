.icon{
    font-size: var(--icon-size);
    /* color: black; */
    display: flex;
}

.checkmarkIcon{
    color: green;
    font-size: var(--icon-size);
    display: flex;
    /* background-color: forestgreen; */
}

.subtractedIcon{
    color: var(--color-red-03);
    font-size: 2.5rem;
    display: flex;
    /* background-color: forestgreen; */
}
.addIcon{
    color: var(--color-red-03);
    font-size: 2.5rem;
    display: flex;
    /* background-color: forestgreen; */
}
.forwardIcon{
    color: var(--color-gray-01);
  font-size: 2.5rem;
    display: flex;
    /* background-color: forestgreen; */
}
.forwardIcon svg{

  
    
    /* background-color: forestgreen; */
}
