.utilityBarContainer {
    padding: 2rem;
    background-color: var(--color-gray-06);
    margin: 2rem 0;
    margin: 4rem 0;
}

.uBarListCountWrapper {
    margin: 2rem 0;

}

.purchaseProIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.purchaseProIcon:hover {
    cursor: pointer;
}

.purchaseProIcon svg {
    font-size: 1.7rem;
    color: var(--color-gray-01);
    margin-right: .3rem;
}

.purchaseProIconText {
    font-size: 1.7rem;
    color: var(--color-gray-01);
    margin-right: .3rem;
}


.uBarListCountGroup {
    display: flex;
    flex-direction: row;
    column-gap: .5rem;
    border-bottom: 1px solid var(--color-gray-04);

    padding-bottom: .5rem;
    margin-bottom: 2rem;
}

.uBarListButtonsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* column-gap: 1rem; */
}

.buttonBox {
    display: flex;
    flex-direction: column;
}
.buttonBox button {
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonBox svg {
    font-size: 2rem;
}
.desktopClearListButtonBox{
    display: none;
}
.mobileClearListButtonBox{
    display: flex;
    align-items: flex-end;
    margin-bottom: .5rem;
}
.uBarListCountText {
    color: var(--color-gray-01);
    font-weight: bold;
    font-size: 3rem;

}

.uBarSubtitleText {
    font-size: 2rem;
    color: var(--color-gray-03);
}

.uBarListCountNumber {
    color: var(--color-red-01);
   font-size: 3rem;
    font-weight: bold;
}

.uBarRegisteredUserInfoContainer {
    background-color: var(--color-gray-06);
    display: inline-flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 2rem;
}

.uBarRegisteredUserInfoWrapper {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
}

.uBarTitleWrapper {
    margin-bottom: 2rem;
}


.userInfoField {
    display: flex;
    flex-direction: row;
}

.userInfoFieldNameText {
    font-size: var(--hero-body-font-size);
    color: var(--color-gray-01);
    margin-right: 1rem;
}

.userInfoText {
    font-size: var(--hero-body-font-size);
    color: var(--color-gray-03);
}

.uBarTitleText {
    font-size: var(--hero-body-font-size);
}


@media (min-width:514px) {}

@media (min-width:768px) {
    .purchaseProIcon svg {
        font-size: 2rem;
    }

    .purchaseProIconText {
        font-size: 2rem;
    }

    .uBarListCountText {
     
        font-size: 4rem;
    }
    
    .uBarSubtitleText {
        font-size: 3rem;
    }
    
    .uBarListCountNumber {
  
       font-size: 4rem;
       
    }
    

}

@media (min-width:1080px) {

    .utilityBarContainer {
        margin: 5rem 0;
        padding: 5rem 5rem 5rem 8rem
    }
}

@media (min-width:1440px) {}

@media (min-width:1920px) {}

/* @media print {
  
    .userInfoField{
        display: block;
    }
    .uBarListButtonsWrapper{
        display: none;
    }
} */
/* @media (min-width: 768px) {}

@media (min-width: 1440px) {}

@media (min-width: 1920px) {}

.utilityBarContainer {}

.uBarListCountWrapper {} */