.productContainer{
    max-width: 180rem;
    margin: 0 auto;
}
.productWrapper {
    display: block;
    background-color: var(--color-gray-06);
    margin: 4rem 0;
    padding: 4rem;
    /* border-radius: 16px; */
   
}

.productImageContainerAII {
    max-width: 20rem;
    max-height: 30rem;
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.productImageContainerAII a {
    height: inherit;
    display: flex;
}

.productImageContainerAII img {
    max-width: 100%;
    /* max-height: 100%; */
    object-fit: contain;
    display: flex;
}

.itemHead {
    grid-column: 2;
    grid-row: 1;
    /* padding: 2rem; */
    color: black;

}

.productTitleBox {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    gap: .2rem;
    /* padding: 2rem; */
    color: black;
    /* margin-bottom: 2rem; */
}
.productTitleBox a{
    text-decoration: none;
    display: inline;
    color: black;
}

.titleCatBox {
    display: flex;
    flex-direction: row;
}

.itemSpecsBox {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    /* padding: 2rem; */
    row-gap: 1rem;
    margin-top: 2rem;
}

.specTitleToolTipGroup {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    border-bottom: 1px solid var(--color-gray-04);
    margin-bottom: 2rem;

}

.expandIcon {
    font-size: var(--text-size-02);
    margin-left: auto;
    color: var(--color-gray-01);
}
.expandIcon:hover {
   cursor: pointer;
}
.specListTitleText {
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-01);
    /* font-weight: bold; */
    padding-bottom: 1rem;

}
.specListTitleText:hover{
    cursor: pointer;
}
.specListItemText {
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03);
    padding: 1rem 0;
}

.specListItemBox {
    margin: 1rem 0;
}

.itemTrash {
    text-align: right;
    display: flex;
    margin-bottom: 2rem;

}
/* .itemTrash svg {
    font-size: 3.3rem;
    background-color: var(--color-gray-04);
    border-radius: 50%;
    padding: 1rem;
 } */
 .itemTrash:hover{
    cursor: pointer;
 }
.availabilityText {
    color: var(--color-gray-03);
    font-size: var(--text-size-01);
    /* font-family: var(--semiBold-title-font-family); */
    margin-bottom: 1rem;
  
}
.categoryText{
    font-size: var(--text-size-02);
    color: var(--color-gray-01);
    font-family: var(--slim-title-font-family);
    /* margin-bottom: 1rem; */
}
.productTitleText {
    font-size: var(--text-size-02);
    font-family: var(--semiBold-title-font-family);
    color: var(--color-gray-03);
}


.subtitleText {
    font-family: var(--semiBold-title-font-family);
    font-size: var(--text-size-03);
    color: black;
}

@media (min-width:514px) {}

@media (min-width:768px) {
    .productWrapper {
      
       /* margin-left:4rem;
       margin-right: 4rem; */
       border-radius: 16px;
    }
}

@media (min-width:1080px) {

    .productWrapper {
        display: grid;
        grid-template-columns: repeat(4, auto);
        /* margin: 2rem 8rem; */
        /* padding: 4rem; */
        padding: 5rem;
        align-items: center;
        justify-content: space-between;
     
    }

    .productHeaderContainer {
        grid-column: 1/2;
    }

    .productImageContainerAII {
        grid-column: 1;
        grid-row: 2;
    }
    .productImageContainerAII img {
       height:100% ;
    }

    .itemSpecsBox {
        grid-column: 1/4;
        margin-left: 30rem;
        display: flex;
    }

    .itemTrash {
        grid-column: 4;
        align-self: baseline;
    }
   
    /* .itemTrash button {
        padding: 1rem;
        margin-left: auto;
     } */



    .expandIcon svg {
        font-size: var(--text-size-0);
        margin-left: auto;
        color: var(--color-gray-01);
    }
/* 
    .availabilityText {
      
        font-size: 2rem;
    }

    .productTitleText {
        font-size: 2rem;
     
    }

    .subtitleText {
        font-size: 2rem;
      
    } */

    /* .productWrapper {

        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(2, auto);
     
    }

    .productImageContainerAII {
        grid-column: 1;
        grid-row: 1/-1;
        max-width: 20rem;
     
        max-height: 20rem;
    }

    .productImageContainerAII img {
        max-width: 100%;
        object-fit: contain;
        display: flex;
    }

    .itemHead {
        grid-column: 2;
        grid-row: 1;
        padding: 2rem;
        color: black;

    }

    .productTitleBox {
        grid-column: 2;
        grid-row: 1;
        padding: 2rem;
        color: black;

    }

    .itemSpecsBox {
        grid-column: 2;
        grid-row: 2;
        display: flex;
        flex-direction: row;
        padding: 2rem;
        row-gap: 2rem;
    }

    .itemTrash {
        grid-column: 3;
        grid-row: 1;
    } */
}

@media (min-width:1440px) {}

@media (min-width:1920px) {}