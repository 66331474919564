.pagination {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: var(--color-gray-01);
  }
  .pagNum{
    color: white;
    font-size: var(--text-size-00);
    margin-left: 1rem;
  }
  .pagNum:hover{
    cursor: pointer;
  }
  .pagination button {
    color: var(--color-gray-07);
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5em 1em;
    margin: 0 1em;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: var(--text-size-00);
  }
  
  .pagination button:disabled {
    color: var(--color-gray-03);
    cursor: not-allowed;
  }
  
  .pagination button:hover:not(:disabled) {
    /* background-color: var(--color-red-03); */
    color: var(--color-gray-04);
  }
  
  .paginationInfo,   .infoText2{
    font-size: var(--text-size-00);
    color: white;
  }
 .infoText {
    font-size: var(--text-size-00);
    font-family: var(--slim-title-font-family);
    color: black;
    margin-top: 1rem;
  }
 


  @media (min-width:514px) {
  
  }
  @media (min-width:768px) {
      .tableData{
          text-align: center;
          padding: 2rem 1rem;
          /* font-size: 1.8rem;
          font-weight: normal; */
      }
  }
  @media (min-width:1080px) {
     
  }
  @media (min-width:1440px) {}
  @media (min-width:1920px) {}