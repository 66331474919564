.container {
    /* margin-top: 10rem; */
    margin-top: 9rem;
    background-color: var(--color-gray-07);
    height: 50rem;
    display: flex;
    line-height: normal;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image:
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)),
        url('../../../Theme/Product/SubcategoryImages/fridge-11.webp')
}

.searchContainer {
    position: relative;
    width: 100%;
    padding: 0 2rem;
}

/* .titleText {
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
    color: white;
}

.subtitleText {
    font-size: var(--text-size-05);
    font-family: var(--slim-title-font-family);
    color: white;
} */

.searchHeaderPreviewWrapper {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 2rem;

}

.searchPreviewBox {
    z-index: 2;
    /* other styles */
}

.noResultPreviewText {
    font-size: var(--text-size-01);
    color: black;
}

@media (min-width:514px) {
    .container {
height: 50vh;
        background-position: center;
    }

}

@media (min-width:768px) {
    .container {
        /* height: 70vh; */
        margin-top: 8rem;

    }

    .searchContainer {
        padding: 0 10rem;
    }

    .searchHeaderPreviewWrapper {
        padding: 0 10rem;
    }

  
    /* .subtitleText {
        font-size: 3rem;
    }
    .noResultPreviewText {
        font-size: 3rem;
     
    } */

}

@media (min-width:1080px) {
    .container {
        /* height: 100vh; */
        margin-top: 12rem;

    }

    .searchHeaderPreviewWrapper {
        padding: 0 20rem;
    }

    .searchContainer {
        padding: 0 20rem;
    }

    /* .titleText {
        font-size: 7rem;
    } */

}

@media (min-width:1440px) {
  

    .searchHeaderPreviewWrapper {
        padding: 0 30rem;
    }

    .searchContainer {
        padding: 0 30rem;
    }
}

@media (min-width:1920px) {

    .searchContainer {
        padding: 0 50rem;
    }

    .searchHeaderPreviewWrapper {
        padding: 0 50rem;
    }
    /* .noResultPreviewText{
        font-size: 3rem;
        color: var(--color-gray-01);
     
    } */
}