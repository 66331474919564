.menuRowToggleBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 1.5rem; */
    margin-bottom: 1rem;
}

.menuRowToggleBoxContainer p {
    font-size: var(--text-size-02);  
    font-family: var(--slim-title-font-family);
}

.menuRowToggleBoxContainer p:hover,
.menuRowToggleBoxContainer svg:hover
 {
    cursor: pointer;
}


.pillMenuRowToggleBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    padding: 1.5rem 1.2rem;
    /* padding: 1.5rem; */
    /* margin-bottom: 1rem; */
    border-radius: 16px;
    background-color: var(--color-gray-05);
    border:1px solid var(--color-gray-04);
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1); */
}
.pillMenuRowToggleBoxContainer:hover{
    background-color: var(--color-gray-04);
}
.pillMenuRowToggleBoxContainer p {
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
}

.pillMenuRowToggleBoxContainer p:hover,
.pillMenuRowToggleBoxContainer svg:hover
 {
    cursor: pointer;
   
}


.iconContainer{}
.iconToggler{
    margin-left:auto ;
}