.tableActionBoxContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;

}
.tableActionBoxContainer svg {
    font-size: var(--icon-size);
}
.tableActionBoxContainer a{
    color: black;
    border-bottom: none;
}
.tableActionBoxContainer a:hover{
    border-bottom: none;
}
.tableActionBoxContainer svg:hover{
    cursor: pointer;
}