.container{
    height: 100rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* margin-top: 20rem; */
    flex-direction: row;
    background-color: var(--color-gray-05);
    margin-top: 5rem;

}
.contentWrapper{

}
.titleText{
    font-size: var(--text-size-05);
    color: black;
}
.imageBox{
    height: 20rem;
 
}
.imageBox img{
height: 100%;
}
@media (min-width: 768px) {
    .imageBox{
        height: 50rem;
    }
   
}
@media (min-width: 1080px) {
    .imageBox{
        height: 70rem;
    }
   
}