.title{
    font-size: var(--text-size-04);
    font-family: var(--slim-title-font-family);
    margin-bottom: 1rem;
}
.subtitle{
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
    margin-bottom: 1rem;
}
.textBox{
    /* margin-top: 2rem; */
}
.dataSection{
    /* border-top: 1px solid var(--color-gray-04);
    border-bottom: 1px solid var(--color-gray-04); */
    /* padding: 1rem 0; */
  
}
.dataRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
}