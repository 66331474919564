.titleText {
    font-size: var(--text-size-03);
    font-family: var(--slim-title-font-family);
    font-family: var(--semiBold-title-font-family);
    color: black;
}

.subtitleText {
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
    color: black;
}

.purchaseProFormContainer {
    margin-top: 2rem;
}

.printScreenContainer {
    display: none;
}

.purchaseProForm {
    background-color: var(--color-gray-06);
}





.productListPageContainer {
    background:
        linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.314)),
        linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.166)),
        url('../../../Theme//Product/CategoryImages/laundry-lifestyle.jpg');
    /* background-size: cover; */
    background-size: inherit;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ppContainer {
    height: 70rem;
    max-width: 60rem;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    /* display: flex;
     */
}


.ppHeader {

    background-color: var(--color-gray-07);
    border-radius: 4rem;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 4rem;
}

.ppSubtitle {
    font-size: var(--text-size-01);
    /* color: white; */
}

.ppTitle {
    /* color: white; */
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
}

.ppTertiaryTitle {
    /* color: white; */
    font-size: var(--text-size-02);
}

.ppHeaderDescription {
    font-size: var(--text-size-01);
    color: var(--color-gray-03);
    /* color: white; */
}
.productListContent{
    margin-top: 11rem;
}


@media (min-width: 1080px) {
    .productListContent{
        margin-top: 20rem;
    }
    .ppContainer {
        margin-top: 17rem;
        min-height: 50rem;
        max-width: 100%;

        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(12, auto);

    }

    .ppHeader {
        background-color: var(--color-gray-07);
        border-radius: 4rem;
        padding: 4rem;
        grid-row: 1/4;
        grid-column: 1/8;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}


@media (min-width: 1280px) {
    .ppHeader {
        background-color: var(--color-gray-07);
        border-radius: 4rem;
        padding: 4rem 6rem;
        grid-row: 1/4;
        grid-column: 1/6;
     
    }
}

@media print {
    .printScreenContainer {
        display: flex;
        flex-direction: column;
    }

    .productListPageContainer {
        display: none;
    }

    .printBlock {
        display: none;
    }
}