.productImageContainer{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}
.productImageContainer img{
    /* max-width: 25rem; */
    max-width: 15rem;
    height: 100%;
    object-fit: contain;
}

.printImage {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.printImage img {
     max-width: 25rem;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 768px) {
    .productImageContainer img{
        max-width: 30rem;
    }
}
@media (min-width: 1440px) {
    .productImageContainer img{
        max-width: 30rem;
    }
}
@media (min-width: 1920px) {
  
   
    
}

@media print {
    .productImageContainer{
        page-break-inside: avoid;
        page-break-after: avoid-page;
          border-bottom: 1px solid var(--color-gray-03);
          padding-bottom: 1rem;
          margin-bottom: 1rem;
          height: auto;
          width: 100%;
          display: flex;
          justify-content: flex-start;
        /* border-bottom: 1px solid var(--color-gray-07); */
        /* padding-bottom: 2rem; */
        /* padding-bottom: 0rem; */
    }
    /* .productImageContainer{
        height: auto;
        width: 100%;
        display: flex;
        justify-content: flex-start;
       
    } */
    .productImageContainer img{
        max-width: 5rem;
        height: 100%;
        object-fit: contain;
    }
    
}

