svg {
    font-size: 1.8rem;
}

.white svg {
    color: white;
}
.black svg {
    color: black;
}
.gray01 svg {
    font-size: 2rem;
    color: var(--color-gray-01);
}
.gray02 svg {
    font-size: 2rem;
    color: var(--color-gray-01);
}
.circularGray05Icon {
    height: 4rem;
    width: 4rem;
    padding: 1rem ;
    background: var(--color-gray-05);
    /* background-color: rgba(0,0,0,0.5); */
    cursor: pointer;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circularGray04Icon {
    height: 4rem;
    width: 4rem;
    padding: 1rem;
    /* padding-top:.5rem ; */

    background: var(--color-gray-04);
    /* background-color: rgba(0,0,0,0.5); */
    cursor: pointer;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circularGray03Icon {
    height: 4rem;
    width: 4rem;
    padding: 1rem ;
    background: var(--color-gray-03);
    /* background-color: rgba(0,0,0,0.5); */
    cursor: pointer;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .baseGray{
    padding: 1rem;
    height: 2rem;
    width: 2rem;

} */
.baseGray svg{
    color: black;
    font-size: 3.3rem;
    padding: 1rem;
    border-radius: 50%;
    background-color: var(--color-gray-04);
}

.circularRed03Icon {
    height: 2.5rem;
    width: 2.5rem;
   
    background: var(--color-red-03);
    /* background-color: rgba(0,0,0,0.5); */
    cursor: pointer;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circularRed03Icon svg {
    /* font-size: 1.5rem; */
    display: flex;
}
.navIcons svg{
    font-size: 3.4rem;
    display: flex;
    color: black;
}
@media (min-width:514px){
    /* .navIcons svg {
      font-size: 3.3rem;
    
    } */
  }

@media (min-width:768px) {
    /* .navIcons svg{
        font-size: 3.3rem;
    } */
    .circularRed03Icon {
        height: 2.8rem;
        width: 2.8rem;
      
    }
    .circularRed03Icon svg {
        font-size: 2.3rem
    }
  
}

@media (min-width:1080px) {
 
    /* .navIcons svg{
        font-size: 3.3rem;
    } */
    .circularRed03Icon {
        height: 2.8rem;
        width: 2.8rem;
      
    }
    .circularRed03Icon svg {
        font-size: 2.3rem
    }
  
}

@media (min-width:1440px) {
    .circularGray03Icon, .circularGray04Icon, .circularGray05Icon {
        height: 5rem;
        width: 5rem;
    }
    

   
    .circularRed03Icon {
        height: 4rem;
        width: 4rem;
      
    }
    .circularRed03Icon svg {
        font-size: 2.5rem
    }
}
@media (min-width:1920px) {
   
   
  }