.portalSectionTextBoxContainer{
    display: flex;
    flex-direction: column; 
    margin: 1.5rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color-gray-04);

}
.titleText{
    font-size: var(--text-size-02);
    font-family: var(--semiBold-title-font-family);
    color: black;
}
.subtitleText, 
.headlineText{
   
    font-family: var(--slim-title-font-family);
    font-size: var(--text-size-01);
    color: var(--color-red-02);
}

