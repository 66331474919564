.printBlock{
    display: hidden;
    /* display: none; */
}

@media print {
    .printBlock{
        display: none;
    }
   
}