.modalContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100vh; */
    height: 100%;
    -webkit-backdrop-filter: blur(5px);  /* For Safari */
    backdrop-filter: blur(5px);

    /* backdrop-filter: blur(5px);   */
    /* Apply a blur of 5 pixels */
    background: rgba(0, 0, 0, 0.307);
    z-index: 10;
}