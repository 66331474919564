.dateText{
    font-size: var(--text-size-00);
}

@media (min-width: 514px) {}

@media (min-width: 768px) {}

@media (min-width: 1080px) {
    .datePickerContainer{
        /* background-color: aqua; */
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        column-gap: 1rem;
    }
   
    .dateBox{
        max-width: 20rem;
    }
    .dateBox p{
     margin-bottom: 1rem; 
        padding-left: .85rem;
    }
    
}