.purchaseProUserContainer {

    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: -2rem;
    margin-bottom: 2rem;
}

.userRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
}
.dataInRow{
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
}
.userRow p {
    color: black;
    font-size: var(--text-size-02);
    font-family: var(--semiBold-title-font-family);
}

.userRow span {
    color: var(--color-gray-03);
    font-size: var(--text-size-02);
}