.switch {
  width: 6rem;
  height: 3rem;
  background-color: white;
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: .2rem .5rem; */
  cursor: pointer;
  border: 1px solid var(--color-gray-05);
  }
  
  .switch[data-isOn="true"] {
    justify-content: flex-end;
  }
  .switchText{
    font-size: var(--text-size-00);
    display: flex;
    padding: .3rem .2rem;
  }
  .handle {
    width: 3rem;
    padding: 0 .2rem;
  
    color: white;
   
    border-radius: 50px;
  }
  