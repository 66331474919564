.bodyContainer{
    /* background-color: black; */
    /* margin: 0 2rem; */

    /* margin:  0 2rem;
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center; */
    display: grid;
    max-width: 180rem;
    margin: 0 auto;
    height: inherit;
    grid-template-columns: minmax(0vw, 4vw)  minmax(auto, 100vw) minmax(0vw, 4vw);
}
@media (min-width:514px) {
    
}
@media (min-width:768px) {}
@media (min-width:1080px) {
    .bodyContainer{
        /* margin: 2rem 8rem 8rem 8rem;   */
        /* max-width: 180rem;
        margin: 0 auto; */
        /* padding: 2rem; */
    }
}
@media (min-width:1440px) {}
@media (min-width:1920px) {

}
