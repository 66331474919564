.confirmation-modal-container {
    /* position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background: rgba(0,0,0,0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-modal {
    /* background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 500px;
    text-align: center; */
    /* position: fixed;
    top: 30vh;
    left: 10%; */
    max-width: 50rem;
    z-index: 100;
    overflow: hidden;
    background: var(--color-gray-07);
    text-align: center; 
    border-radius: 16px;
  }
  .header {
    background: var(--color-primary-dark);
    padding: 1rem;
}

  .confirmation-modal h2 {
    /* font-size: 24px;
    margin-top: 0; */
    margin: 0;
    color: var(--color-primary-light-gray);
    font-size: 2rem;
  }
  
  .confirmation-modal p {
    margin: 20px 0;
    color: var(--color-primary-dark);
  }
  
  
  .confirmation-modal button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .confirmation-modal .confirm-btn {
    background-color: var(--color-primary-dark);
    color: #fff;
  }
  /* .confirmation-modal .confirm-btn:hover {
    background-color: var(--color-primary-red);
    color: #fff;
  }
   */
  .confirmation-modal .cancel-btn {
    background-color: white;
    border: 1px solid gainsboro;
    color: var(--color-primary-dark);
  }
  /* .confirmation-modal .cancel-btn:hover{
    background-color: gainsboro;
   
    color: var(--color-primary-dark);
  } */

  .content {
    padding: 1rem;
}

.content p {
  font-size: 1.5rem;
  line-height: 1.8;
}

.actions {
    padding: 1rem;
    display: flex;
    justify-content: center;
}
