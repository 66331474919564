.welcomeTileContainer{
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 16px;
    /* margin-top: 2rem; */
    margin-bottom: 2rem;
    font-family: var(--slim-title-font-family);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: 
    linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3)),
    url('../../../../Shared/Theme/Product/SubcategoryImages/laundry-23.webp');
 
}
.clockWrapper, .xButtonText{
    display: none;
}
.clockWrapper p {
    color: var(--color-gray-04);
}
.item1{
  margin-bottom: 5rem;
}
.item3{
    margin-bottom: 1rem;
}
.linkIconBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    /* margin-bottom: .5rem; */
}
.textBox{
    color: white;
    font-family: var(--title-font-family);
}
.title {
    font-size: var(--text-size-05);
}
.subtitleText{
    color: var(--color-gray-04);
    font-family: var(--title-font-family);
    font-size: var(--text-size-00);
}
.linkText{
    font-size: var(--text-size-01);
    color: white;
}

.linkBoxWrapper svg{
    color: var(--color-gray-07);
    /* color: white; */
}

.linkBoxWrapper a{
    text-decoration: none;
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03);
}

.buttonRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}
.textBox{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.buttonTextBox{
    display: flex;
    flex-direction: row;
    gap: .5rem;
}


@media (min-width: 514px) {
    .welcomeTileContainer{
        /* margin-top: 6rem; */
        margin-bottom: 2rem;
        padding: 4rem;
    }
    .clockWrapper{
        display: flex;
        flex-direction: row;
        gap: .5rem;
    }
    .xButtonText{
        display: block
    }
}

@media (min-width: 768px) {
    .welcomeTileContainer{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, auto);
        align-items: center;
    }
    .item1{
        grid-column: 1/7;
        grid-row: 1;
    }
    .item2{
    
        grid-column: 1/6;
        grid-row: 2;
    }
    .item3{
    
        grid-column: 6/-1;
        grid-row: 2;
        margin-bottom: 0;
    }
    .item4{
    
        grid-column: 6/-1;
        grid-row: 1;
    }
    .buttonRow{
        row-gap: 1rem;
    }
    .linkBoxWrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 2rem;
        align-items: center;
    }
  
    
}

@media (min-width: 1080px) {

    .welcomeTileContainer{
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, auto);
        margin-top: 0rem;
    }

  
    .buttonRow{
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
    .linkBoxWrapper svg:hover{
        color: var(--color-gray-03);
       
    }
    .linkText:hover{
        color: var(--color-gray-03);
        cursor: pointer;
        /* margin-bottom: .5rem; */
    }
}

@media (min-width: 1440px) {}

@media (min-width: 1920px) {}