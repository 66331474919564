.portalFormRowContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    /* margin-top: .5rem; */
    margin-bottom: 1rem;
    /* padding-right: 2rem; */
}
.portalFormRowContainer button{
    margin-top: 2rem;
}
@media (min-width:514px) {}

@media (min-width:768px) {}

@media (min-width:1080px) {}

@media (min-width:1440px) {
    .portalFormRowContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        column-gap: 5rem;
        margin-bottom: 2rem;
        align-items: start;
        
    }
}

@media (min-width:1920px) {}