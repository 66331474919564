.navigationLinksContainer {
 /* margin-bottom: 2rem; */
 /* margin-bottom: 1rem; */
}
.menuLinks{
  /* background-color: aqua; */
   margin-bottom: 1.5rem;
}

.navigationLinksContainer a,
li {
  transform: scale(1);
  transition: transform 0.3s ease, border-color 0.3s ease;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  font-size: var(--text-size-01);
  font-family: var(--slim-title-font-family);
}

.navigationLinksContainer a:hover,
.navigationLinksContainer li:hover {
  border-bottom: 2px solid var(--color-red-03);
  /* transform: scale(1.05); */
  transform: scale(1);
  border-bottom-color: var(--color-red-03);
}
.purchaseProHeaderLink a{
  transform: scale(1);
  transition: transform 0.3s ease, border-color 0.3s ease;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  font-size: var(--text-size-02);
  font-family: var(--slim-title-font-family);
}
.active {
  border-bottom: 2px solid var(--color-red-03);
}
@media (min-width: 1080px) {
  .navigationLinksContainer {
    margin-bottom: .5rem;
   }
   
}