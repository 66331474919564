.searchInputBox {
    position: relative;
    /* left: 57px; */
}
.searchIcon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
}
.searchInputBox input {
    border-radius: 6px;
    border: none; /* remove the default border */
    border-bottom: 2px solid transparent; /* default bottom border */
    transition: border-bottom 0.3s ease-in-out; /* smooth transition to the border */
    font-size: var(--icon-size); 
    padding: 1.25rem 1rem;
}


/* Change the bottom border color when typing */
.searchInputBox input:focus {
    outline: none; /* remove default focus outline */
    /* border-bottom: 3px solid var(--color-gray-01);  */
}
.labelText, .searchInputBox input {
    font-size: var(--text-size-00);
}
.searchInputBox input::placeholder {
    font-size: var(--text-size-00);
}
/* Styles for tablets and larger devices */
@media (min-width: 768px) {
    /* .labelText, .searchInputBox input {
        font-size: 1.5rem;
       
    } */


  
}
@media (min-width: 1440px) {
    

    .searchInputBox input {
        padding: 1.5rem 1.75rem;
    }
}

