.mainNavGroupContainer{
 display: flex;
 flex-direction: column;
 gap: 100rem;
}

.mainNavContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--color-gray-07);
    border-bottom: 0.05rem solid var(--color-gray-04);
    
}
.welcomeBarWrapper{

margin-top: 40rem;
z-index: 1000;

}


.desktopNavWrapper {
    display: none;
}


/*----------------------/*
-MOBILE
/*----------------------*/
.mobileNavSection1 {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;
    height: auto;
    /* padding: 1rem 1.4rem; */
    padding: 1.5rem 1.4rem;
    align-items: center;
    background-color: white;
    border: 1px solid var(--color-gray-05);
    border-radius: 200px;
}

.mobileLogoBox {
    margin-right: auto;
    /* margin-left: 2rem; */
    display: flex;
    align-items: center;
}

.mobileNavGeneralBox {
    display: flex;
    flex-direction: row;
    column-gap: 1.7rem;
    margin-right: 1rem;
    /* margin-right: 2rem; */
    align-items: center;
}

.mainMobileDropdown {
    /* height: 100%; */
    height: 100vh;
    overflow-y: scroll;
    background-color: var(--color-gray-07);
    border-top: 1px solid var(--color-gray-04);
    padding-top: 2rem;

}

.mobileDropdownSection {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
}


.dropdownContainer {
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    background-color: var(--color-gray-06);
    top: 100%;
    width: 100%;
    z-index: 100;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 4rem;
    /* padding-bottom: 4rem; */
    overflow-y: scroll;

}


.catDropdownGroup {
    display: flex;
    flex-direction: column;
    /* margin-top: 1rem; */
    margin-right: 2rem;

}

.catDropdownGroup a {
    color: var(--color-ui-gray-02);
    font-size: 2.8rem;

    /* margin-bottom: 2rem; */
    padding-bottom: 2rem;
    text-decoration: none;
}


.menuClickText {
    color: var(--color-gray-01);

    font-size: var(--navigation-header-font-size);
    font-family: var(--title-font-family);
    /* padding-bottom: 2rem; */
    padding: 2rem 2rem 2rem 4rem;
}

.menuClickBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-right: 2rem; */
    padding-right: 2rem;
    border-bottom: 1px solid var(--color-gray-05);

}

.menuClickBox span {
    margin-left: auto;
}

.externalNavLink a{
    color: var(--color-gray-01);
    font-size: 2rem;
    text-decoration: none;
    margin-bottom: 1.934rem;
}

.purchaseProSectionContainer {
    padding: 2rem;
    border-top: .1rem solid var(--color-gray-04);
    border-bottom: .1rem solid var(--color-gray-04);
}


.languageSelectionContainer {
    padding: 2rem 4rem;

    border-bottom: .1rem solid var(--color-gray-04);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
}

.hubSelectionContainer {
    padding: 2rem;

    border-bottom: .1rem solid var(--color-gray-04);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
}


@media (min-width:514px) {}

@media (min-width:768px) {}

/*----------------------/*
-DESKTOP
/*----------------------*/

@media (min-width:1080px) {
    .mobileNavWrapper {
        display: none;
        background-color: var(--color-gray-03);
    }

    .desktopNavWrapper {
        display: block;
        max-width: 180rem;
        margin: 0 auto;
        /* padding-left: 8rem;
        padding-right: 8rem; */

    }

    .desktopNavSection1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 10rem;
        /* padding-right: 8rem; */
        background-color: var(--color-gray-07);
        border-radius: 0;
        height: 8rem;
        /* height: 11rem; */
        /* height: auto; */
        /* padding: 0 4rem; */
    }

    .desktopLogoBox {
        /* margin-right: 4rem;
        margin-left: 5.6rem; */
        margin-right: 4rem;
        display: flex;
    }

    .desktopCategoryBox {
        margin-right: auto;
        display: flex;
        align-items: center;
        column-gap: 3.6rem;
        padding: 2.8rem 1.8rem 1rem 1.8rem;
    }


    .desktopNavHeaderLinkText {
        /* font-size: var(--text-size-02); */
        font-size: 2rem;
        /* font-size: var(--nav-link-font-size); */
        font-family: var(--slim-title-font-family);
        position: relative;
        color: black;
        transition: transform 0.3s ease, border-color 0.3s ease;
        border-bottom: 2px solid transparent;
        display: flex;
        align-items: center;
        z-index: 101;

    }


    .desktopNavHeaderLinkText:hover {
        cursor: pointer;
        border-bottom: 2px solid var(--color-red-03);
      
        /* transform: scale(1.05); */
        /* border-bottom-color: var(--color-red-03); */
    }

    .externalNavLink a{
        font-size: 2rem;
        font-family: var(--slim-title-font-family);
        text-decoration: none;
        margin-bottom: 0.3rem;
    }
 

    /* NAV SEARCH */
    .navSearchComponentContainer {
     background-color: var(--color-gray-06);
     border-top: 1px solid var(--color-gray-04);
     position: relative;
     z-index: 0;
    }

    .navSearchDesktopInput{
        /* padding: 1rem; */
        padding-bottom: 1rem;
        display: flex;
        justify-content: center;
    }
    .navSearchDesktopInput input{
        width: 100rem;
        height: 5rem;
        /* border-radius: 3rem; */
    }

    /* ---------------- DROPDOWN STYLES --------------------- */
    .desktopCategoryDropdownWrapper {
        position: absolute;
        top: 45%;
        width: max-content;
        background-color: var(--color-gray-07);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        /* backface-visibility: hidden; */
        z-index: 1;
    }


    .desktopCategoryDropdown {
        display: flex;
        flex-direction: row;
        z-index: 100;
        column-gap: 8rem;
        margin: 4rem 2rem 4rem 3rem;
        padding-right: 5rem;
    }

    .active {
        border-bottom: 2px solid var(--color-red-03);
    }

    .desktopDropdownContent a {
        text-decoration: none;
        color: black;
        /* font-size: var(--nested-nav-link-font-size);  */
    }

    .desktopNavLinkGroup {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        position: relative;
    }

    .desktopNavLinkGroup a {
        /* font-size: var(--nested-nav-link-font-size);
        font-size: 2rem; */
        position: relative;
        z-index: 1;
        width: fit-content;
        transform: scale(1);
        transition: transform 0.3s ease, border-color 0.3s ease;
        border-bottom: 2px solid transparent;
    }

    .desktopNavLinkGroup a:hover,
    .desktopNavLinkGroup a:active {
        /* transform: scale(1.05); */
        border-bottom-color: var(--color-red-03);

        /* text-decoration: underline;
        text-decoration-color: var(--color-red-03); */

    }

    .desktopNavGroupTitle {
        font-size: var(--nested-nav-link-font-size);

        color: black;
        display: inline-flex;
        /* margin-bottom: .2rem; */
    }

    .desktopStudioNavGroupTitle {
        font-size: var(--nav-link-font-size);
        /* font-family: var(--title-font-family); */
        /* margin-top: .5rem; */
        margin-bottom: .2rem;
    }

    /* --------------------RESOURCES---------------------- */
    .desktopResourcesBox {
        margin-right: auto;
        /* display: flex; */
    }


    /* --------------------PURCHASE PRO---------------------- */
    .purchaseProLinkBox {
        z-index: 10;
    }

    .purchaseProLinkBox a {
        color: black;
        font-size: 2rem;
        /* font-size: var(--text-size-02); */
    }



    /* --------------------ACCOUNT AREA---------------------- */
    .desktopNavGeneralGroupWrapper {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        align-items: center;
    }

    .desktopAccountBox:hover {
        cursor: pointer;
    }

    .desktopNavAccountWrapper {
        position: relative;
    }

    .desktopAccountDropdownWrapper {
        position: absolute;
        top: 95%;
        width: max-content;
        background-color: var(--color-gray-07);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        z-index: 0;
        padding: 4rem 2rem;
        /* border-radius: 16px; */
    }
}

@media (min-width:1280px) {}

@media (min-width:1440px) {
    /* .desktopNavWrapper {
       
        padding-left: 20rem;
        padding-right: 20rem;
    
    } */
}

@media (min-width:1801px) {
    .desktopNavSection1 {
        padding: 0rem;
    }
}

@media print {
    .mainNavContainer {
        display: none;
    }
}