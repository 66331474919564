input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

textarea {
  overflow: auto; /* Ensures scrollbars appear if the content exceeds the textarea's dimensions */
}

textarea::-webkit-resizer {
  display: none; /* Hides the default resize handle in Webkit browsers (Chrome, Safari) */
}

.textInputContainer {
  position: relative;
 
}


.textAreaInputContainer textarea {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-bottom: 1rem;
  padding-top: 1rem; */
  width: 100%;
}


.textInputWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  /* margin-bottom: 1rem;
  margin-top: 2rem; */
}

.labelBox {
  display: flex;
  flex-direction: row;

}


.labelText {
  margin-top: .5rem;
  font-size: var(--text-size-01);
  font-family: var(--slim-title-font-family);
  color: black;
}

.errorText {
  font-size: var(--text-size-00);
  color: var(--color-red-02);
  position: absolute;
  top: 95%;
  margin-top: .5rem;
  margin-bottom: .5rem;
}



input,
textarea {
  /* padding: 0.8rem 1rem; */
  padding: 1.25rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 8px;
  border-bottom: 2px solid transparent;

  transition: border-bottom 0.3s ease-in-out;

  width: 100%;
  font-size: var(--text-size-01);
  font-family: var(--slim-title-font-family);
  color: black;
  background-color: white;
}

.inputInvalid {
  background-color: #fdf5f5;
  /* border-bottom: 2px solid var(--color-red-03); */
}

input:focus,
textarea:focus {
  outline: none; /* remove default focus outline */
  border-bottom: 3px solid var(--color-gray-04); /* replace with your desired color */
}

.inputIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-gray-03);
  cursor: default;
}

.inputIcon.clickable {
  cursor: pointer;
}

.requiredAsterix {
  color: var(--color-red-03);
}


.errorTextPlaceholder {
  min-height: 1rem;
  position: relative;
}

/* Styles for tablets and larger devices */
@media (min-width: 768px) {
  .formContainer {
    margin: 5rem;
  }



  /* input {
    padding: 0.75rem 1.25rem;
  } */
}
@media (min-width: 1080px) {


}
/* Styles for laptops and larger devices */
@media (min-width: 1440px) {


}

/* Styles for larger desktop screens */
@media (min-width: 1920px) {
  .formContainer {
    margin: 8rem;
  }


  /* input {
    padding: 1.2rem 1.14rem;
  } */
}