.colorLegendContainer {
  margin-top: 2rem;
  border-radius: 14px;
}

.colorLegendWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex-wrap: wrap;
  
}

.selectedColorText {
  font-size: var(--text-size-01);
  color: black;
  font-family: var(--slim-title-font-family);
}

.colorRow {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  /* column-gap: 2rem; */
  /* margin: 1rem; */
  align-items: center;
}

.swatchColor {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

@media (min-width: 768px) {


  .colorLegendWrapper {
    flex-direction: row;
    column-gap: 3rem;
    align-items: center;
  }

}

@media (min-width: 1440px) {
  /* .selectedColorText {
    font-size: 1.8rem;
  } */

  .swatchColor {
    height: 3.5rem;
    width: 3.5rem;

  }

  .colorLegendWrapper {
    column-gap: 6rem;
    /* margin-top: auto; */
  }


}

@media (min-width: 1920px) {
 
}


@media print {
  .colorLegendContainer {
    margin: 0rem;
    border-radius: none;
  }
  .swatchColor {
    height: 0;
    width: 0;
    border-radius: none;
  }
}