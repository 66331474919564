@media print {

    .featureLogoSectionWrapper {
        background-color: white;
        border-top: none;

    }

    .logoWrapper {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        /* align-items: flex-end; */
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: 2rem;


    }

    /* .logoWrapper {
        margin: 1rem 0 1rem 0;
    } */

    .logo {
        height: 8rem;
        width: 8rem;
        /* width: 5rem; */
    }

    .logo img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}