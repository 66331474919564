.cardContainer a{
    text-decoration: none;
}
.categoryCardSectionWrapper{
    margin-top: 14rem;
}
.categoryCardContainer {
    padding: 2rem;
    /* box-shadow: 0px -25px 20px -20px rgba(0, 0, 0, 0.245), 0px 25px 20px -20px rgba(0, 0, 0, 0.245); */
}
.cardImageWrapper {
    height: auto;
    width: 100%;
    padding: 2rem 2rem 1rem 2rem; /* Decreased padding for mobile */
    display: flex;
    justify-content: center;
    
}
.cardImageWrapper img {
    width: 100%;
    height: 20rem;
    object-fit: contain;
 
}
/* .title{
    font-size: 1.8rem;
    font-family: var(--title-font-family);
} */

.categoryCardContainer a{
    text-decoration: none;

}
.categoryCardContainer p:hover{
    text-decoration: none;
    color: var(--color-gray-02);
}
.subcategoryText{
    font-size: 2.2rem;
    font-family: var(--title-font-family);
    color: var(--color-gray-01);
}
.categoryText {
    font-size: 1.7rem;
    font-family: var(--title-font-family);
    color: var(--color-gray-03);
}

@media (min-width: 768px) {
    .categoryCardContainer {
        padding: 1rem; /* Increased padding for larger screens */
    }
    .cardImageWrapper {
        padding: 2rem 2rem 1rem 2rem; /* Increased padding for larger screens */
    }
    .cardImageWrapper img {
        height: 20rem;
    }
}

@media (min-width: 1080px) {
     .cardImageWrapper img {
        height: 24rem;
    }
    
  }
@media (min-width: 1440px) {
    .categoryCardContainer {
        padding: 3rem; 
        /* Increased padding for larger screens */
    }
    .cardImageWrapper {
        /* padding: 5rem 5rem 3rem 5rem; */
         /* Increased padding for larger screens */
    }
    .cardImageWrapper img {
        height: 30rem;
    }
}
@media (min-width: 1920px) {
 
    /* .cardImageWrapper img {
           height: 20rem;
           
    } */
}
