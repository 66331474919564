.selectRow {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0 2rem 0;
}

.selectRow label {
  display: flex;
  flex-direction: row;
  padding-bottom: .5rem;
  margin-bottom: .5rem;

}
.selectLabel{
  font-family: var(--slim-title-font-family);
  font-size: var(--text-size-01);
  color: black;
}

select {
  /* font-family: inherit; */
  padding: 1.25rem 1rem;
  border: none;
  border-radius: 8px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
  width: 100%;
  font-size: var(--text-size-00);
  color: var(--color-gray-02);
  background-color: white;
}

select:focus {
  outline: none;
  box-shadow: var(--shadow-dark);
  border-bottom: 3px solid var(--color-gray-04);
}

.requiredAsterix {
  color: var(--color-red-03);
}


