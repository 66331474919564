.templateWrapper {
    padding: 10rem 0 4rem 0;
    /* min-height: 100rem; */
    background-color: var(--color-gray-06);
}


@media (min-width:514px) {
 

}

@media (min-width:768px) {
 
}

@media (min-width:1080px) {
    .pageContainer{
        min-height: 50rem;
    }
}

@media (min-width:1440px) {}

@media (min-width:1920px) {}