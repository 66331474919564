.labelText{
    font-family: var(--slim-title-font-family);
    font-size: var(--text-size-01);
    color: black;
}
.labelInfoText{
    font-family: var(--slim-title-font-family);
    font-size: var(--text-size-00);
    color: var(--color-gray-03);
}
.labelTextWrapper{
margin-top: 2rem;
}
.specsTextAreaWrapper textarea {
    font-family: var(--slim-title-font-family);
    font-size: var(--text-size-01);
    color: black;
}
.imagePreviewBox{
    height: 20rem;
    }
.imagePreviewBox img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.fimgwrap{
    background-color: white;
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  min-height: 35rem;
  padding: 4rem;
  border-radius: 16px;
  
}
@media (min-width: 1080px) {
    .fimgwrap{
      width: 50%;
      min-height: 35rem;
      padding: 5rem;
    }
}