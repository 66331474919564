.sidebarWrapper {
    width: auto;
    /* display: flex;
    flex-direction: column; */
    /* margin-bottom: 5rem; */
    /* position: relative; */
    position: relative;
    /* margin-top: 4rem; */
    /* height: 100vh; */
}

.tabColumn {
    background-color: var(--color-gray-05);
    border: 1px solid var(--color-gray-03);
    /* padding: 0 1rem; */
    color: black;
    /* display: flex; */
    position: absolute;
    top: 0%;
    padding: 1rem;
    
    justify-content: flex-start;
    margin-top: 2rem;
    left: 3%;
    border-radius: 16px;

    z-index: 1;
}

.tabColumn svg{
    color: var(--color-gray-01);
}
.tabColumn svg:hover {
    cursor: pointer;
}

.menuRow {
  display: flex;
  flex-direction: column;
  /* padding: 0 2rem; */
  /* border-radius: 16px;
  background-color: var(--color-gray-05); */
}

.sidebarMenuWrapper {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(2, 1fr); */
    background-color: var(--color-gray-07);
    /* padding: 0 0 2rem 1rem; */
    row-gap: 1rem;
    /* margin-top: -4rem; */
    z-index: 0;
    padding:6.5rem 2rem 2rem 2rem 
}
#menuRowTopBox{
    display: inline;
    margin: 1rem 0 1rem 0;
}
.rowHeaderTitle{
    font-size: var(--text-size-02);
    color: black;
    font-family: var(--semiBold-title-font-family);

}
.rowDropdownBox {
    position: relative;
    /* background-color: var(--color-gray-05); */
    /* padding: 1rem; */
    height: 100%;
    border-radius: 16px;
    margin-left: 1rem;
    margin-top: 1rem;
}

.rowDropdownBox p {
    font-size: var(--nav-link-font-size);
    font-family: var(--slim-title-font-family);
    /* color: var(--color-gray-03); */
    width: fit-content;
    padding: .5rem;
    /* transition: transform 0.3s ease, border-color 0.3s ease;
    border-bottom: 2px solid transparent */
}
.rowDropdownBox a{
    color: var(--color-gray-03);
    text-decoration: none;
}


.rowDropdownBox p:hover {
    cursor: pointer;
       /* border-bottom: 2px solid var(--color-red-03); */

}

.rowHeaderBox {
    column-gap: 1rem;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  
   
}

.rowHeaderBox:hover{
    cursor: pointer;
}
.rowHeaderText {
    /* display: none; */
    /* font-size: var(--nav-link-font-size); */
    color: var(--color-gray-01);
    margin-top: 0rem;
}
.rowColumnHeaderText{
    font-size: var(--nav-link-font-size);
    color: var(--color-gray-02);
    /* text-transform: uppercase; */
}
.rowHeaderIcon svg {
    /* display: none; */
    color: var(--color-gray-02);
    /* font-size: var(--body-font-size); */
    display: flex;
}

.menuExpandIcon svg {
    display: none;
}


@media (min-width: 1080px) {
    .sidebarWrapper {
        width: auto;
        /* display: flex;
        flex-direction: row; */
        /* margin-bottom: 0rem; */
        /* padding: 0 4rem; */
        /* margin-top: 2rem; */
       
    position: relative;
    padding-top: 0rem; 
    margin-top: 2rem;
    }
    .sidebarMenuWrapper{
        padding: 1rem 8rem 0 2rem;
        row-gap: 0;
        width: 40rem;
     
    }
.tabColumn{
    margin: 0rem 0rem 0 2rem;
    top: 0%;
    padding: 1rem;
    left: 70%;
    background-color: var(--color-gray-05);
   
}

.sidebarWrapper {
   
    /* margin-top: 4rem;   */
}





    .menuRow {
        position: unset;
        padding: 1rem 0rem ;
    }  
/*     
    .menuRow:hover{
      background-color: var(--color-gray-04);
  } */

    .rowHeaderBox{
        /* padding: 1.2rem 0; */
    }

    .rowDropdownBox {
        position: unset;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        margin-top: 1rem;
    }
    .menuExpandIconWrapper{
        display: flex;
        /* margin-left: auto; */
    }
    .menuExpandIcon svg {
        display: flex;
        color: var(--color-gray-02);
       
    }

}

/* @media (min-width: 514px) {}

@media (min-width: 768px) {}

@media (min-width: 1440px) {}
@media (min-width: 1920px) {} */
@media (min-width: 1920px) {
    .tabColumn{
      
        left: 76%;
       
       
    }
} 