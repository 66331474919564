.mobileCard {
    /* padding: 0 1rem; */
    /* box-sizing: border-box; */
    /* background-color: rgb(43, 100, 100); */
    background-color: var(--color-gray-06);
    border-radius: 16px;
}

.desktopCard {
    display: none;
}

.categoryPreviewCardWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3.3rem;
    margin: 2rem;
}

@media (min-width: 514px) {
    .mobileCard {
        display: none;

    }

    .desktopCard {
        display: block;
        padding: 0 1rem;
        background-color: var(--color-gray-05);
        /* border: 1px solid var(--color-gray-03); */
        border-radius: 16px;
    }
    .desktopCard:hover{
        
    }

    .categoryPreviewCardWrapper {
        grid-template-columns: repeat(2, 1fr);
        /* Adjust number of columns at this breakpoint */
    }
}

@media (min-width: 768px) {

    /* .mobileCard {
       display: none;
    
    }
    .desktopCard {
        display: block;
        padding: 0 1rem;
       
    
    } */
    .categoryPreviewCardWrapper {
        /* margin-top: -5rem; */

    }
}

@media (min-width: 1080px) {
    .categoryPreviewCardWrapper {
        /* margin-top: -5rem; */
        grid-template-columns: repeat(3, 1fr);
        /* Adjust number of columns at this breakpoint */
    }

}

@media (min-width: 1440px) {
    .categoryPreviewCardWrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1920px) {
    .categoryPreviewCardWrapper {
        grid-template-columns: repeat(6, 1fr);
    }

    .categoryPreviewCardWrapper {
        margin: 5rem 0
            /* border-top: 1px solid var(--color-gray-01);
        border-bottom: 1px solid var(--color-gray-01); */


    }

}