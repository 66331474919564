.utilityBarContainerWrapper{
    background-color: var(--color-gray-06);
    /* border-top: 1px solid var(--color-gray-04);
    border-bottom: 1px solid var(--color-gray-04); */
    margin-top: 4rem;
   
}
.utilityBarContainer {
     /* background-color: rgb(6, 26, 26); */
     border: none;
    /* padding: 2rem; */
    /* background-color: var(--color-gray-06); */
    /* margin: 2rem 0;
    margin: 4rem 0; */
    /* max-width: 180rem;
    margin: 0 auto; */
}

.utilityBarHeader{
    /* margin: 4rem 0 0 0; */
    padding: 4rem 0;
   
    /* line-height: normal; */
}
.textBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.titleText{
    font-size: var(--text-size-05);
    color: black;
    font-family: var(--slim-title-font-family);
}
.subtitleText{
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03);
}
.countText{
    font-size: var(--text-size-02);
    /* font-family: var(--semiBold-title-font-family); */
    color: var(--color-red-03);
    margin-left: 1rem;
}
.titleTextCountWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    gap: 2rem;
}
.buttonsWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}
@media (min-width:768px) {
    .utilityBarHeader {
        padding: 4rem;
        /* margin: 5rem 0;
        padding: 5rem 5rem 5rem 8rem */
    }
}
@media (min-width:1080px) {

    .utilityBarHeader {
        padding: 4rem 0;
        /* margin: 5rem 0;
        padding: 5rem 5rem 5rem 8rem */
    }
}