@keyframes gradientAnimation {
    0% {
        background-position: 0% 0%;
    }

    25% {
        background-position: 50% 50%;
    }

    50% {
        background-position: 100% 0%;
    }

    75% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}

.welcomeBar {
    /* position: fixed;
    top: 0;
    left: 0;
    right: 0; */
    /* position: fixed; */
    z-index: 1000;

    background-color: var(--color-gray-07);
    border-bottom: 0.05rem solid var(--color-gray-04);
    background-color: var(--color-red-03);
    align-items: center;
    padding: .5rem 0;
    background-size: 200% 200%;
    background-image: linear-gradient(45deg, #ff7f7f, #ff1e1e, #ffcccc);
    animation: gradientAnimation 5s ease infinite;
    /* margin-bottom: 150rem; */
    /* height: 100vh; */

}

/* HIDE DESKTOP WELCOME BAR */
.longWelcome {
    display: none;
}

.shortWelcome {
    display: flex;
    justify-content: space-between;
    /* padding: 0 2rem; */
    /* padding: .4rem 2rem; */
}

.welcomeBar svg {
    color: white;
    font-size: var(--icon-size);
}

.subtitleText {
    color: white;
    font-size: var(--text-size-00);
}

.welcomeBar a {
    color: white;
}


@media (min-width:768px) {

    /* HIDE DESKTOP WELCOME BAR */
    .shortWelcome {
        display: none;
    }

    .longWelcome {
        display: flex;
        justify-content: space-between;
        /* padding: .4rem 4rem; */
        /* padding: 0 4rem; */
        /* padding: .5rem 4rem; */
    }

    .longWelcome a {
        margin-left: .5rem;
    }
}

@media (min-width:1080px) {

    /* .welcomeBarWrapper {
        max-width: 180rem;
        margin: 0 auto;
    } */

    .longWelcome {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}
@media print {
    .welcomeBar {
        display: none
    }
}
@media (min-width:1440px) {}

@media (min-width:1920px) {}