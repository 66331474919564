.builderIconContainer {
  width: auto;
  position: relative;
  cursor: pointer;
}
.builderIconContainer span{
  font-size: var(--text-size-00);
}

.itemCountSingleDigit {
  position: absolute;
 
  bottom: 1.7rem;
  left: 1rem;
  color: white;
  background-color: var(--color-red-02);
  border-radius: 50%;
  padding-left: 1rem;
  padding-top: .5rem;
  margin-bottom: .2rem;
  height: 3rem;
  width: 3rem;
}
.listIcon {
  color: var(--color-gray-01);
  width: 2rem;
  height: 2rem;

}
.itemCountDoubleDigit {
    position: absolute;
    font-size: 1.4rem;
  
    bottom: 1.7rem;
    left: 1rem;
    color: white;
    background-color: var(--color-red-03);
    border-radius: 50%;
    padding-left: .5rem;
    padding-top: .3rem;
    height: 3rem;
    width: 3rem;
  
    /*position the count within the icon*/
  }
  