.portalFormContainer{
   
}
.userInfoWrapper{

}
.portalFormWrapper{
   
}
.rlText{
    font-size: var(--text-size-00);
}

@media (min-width:514px) {
    .portalFormContainer{
        /* margin-top: 6rem; */
        
    }
}

@media (min-width:768px) {
  
    
}

@media (min-width:1080px) {
   

    .portalFormContainer{
        /* margin-top: 10rem; */
    }
}

@media (min-width:1440px) {

}

@media (min-width:1920px) {

}