.userInfoWrapper{
    /* background-color: aqua; */
    font-family: var(--slim-title-font-family);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 1rem;
}
.userInfoHeader{
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: black;
}
.userInfoDataText{
    font-size: var(--text-size-01);
    font-family: var(--semiBold-title-font-family);
    color: var(--color-red-03);
    padding-left: .5rem;
}
