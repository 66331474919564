.pageWrapper {
    margin: 8rem 0 0 0;
}
@media (min-width: 514px) {
   
}

@media (min-width: 768px) {
    .pageWrapper {
      
        /* display: grid;
        grid-template-columns: auto 1fr; */
    }
}


@media (min-width: 1080px) {
    .pageWrapper {
        margin: 14rem 0rem 0rem 0rem;
        /* display: grid;
        grid-template-columns: auto 1fr; */
    }
    .portalContainer{
        display: grid;
        grid-template-columns: auto 1fr;
    }
    
}

