.portalFormImageUploadSectionWrapper{
    margin: 1rem 0;
}
.textBox{
    margin:2rem 0;
}

.sectionLabelText{
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: black;
}
.noteText{
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03);
}
.imageUploadRow{

}
.imagePreviewBox{
height: 20rem;
}
.imagePreviewBox img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}
/* Styles for laptops and larger devices */
@media (min-width: 1440px) {
    /* .portalFormImageUploadSectionWrapper{
        margin: 1rem 0;
        
        max-width: 80rem;
    } */
}

/* Styles for larger desktop screens */
@media (min-width: 1920px) {
 
}