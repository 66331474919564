/* DONE */
.upcCodesSectionWrapper {
  background-color: var(--color-gray-06);
 padding: 2rem 0;
    border-top: 1px solid var(--color-gray-04);
}

.upcCodesContainer {
  /* max-width: 180rem;
  margin: 0 auto; */
}
.titleBox {
  margin-bottom: 2rem;
}
.titleText {
  font-size: var(--text-size-02);
  /* line-height: 1.8; */
  color: var(--color-gray-02);
  font-family: var(--semiBold-title-font-family);
  /* margin-bottom: 1rem;
  padding-bottom: 1rem; */
  /* border-bottom: 1px solid var(--color-gray-04); */
}

/* .titleBox {
  padding-bottom: 1rem;
} */


.upcCodeWrapper {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;

}

.upcCodeText {
  font-size: var(--text-size-01);
  font-family: var(--slim-title-font-family);
  color: var(--color-gray-03);
  /* border-bottom: 1px solid var(--color-gray-04); */
  /* padding-bottom: .7rem; */

}


@media (min-width: 768px) {


  .titleText {
    /* padding-bottom: 1rem; */
  }


  .upcCodeText {
    margin: 1rem 0;
    /* padding-bottom: 2rem; */

  }

  .upcCodeWrapper {
    row-gap: 0rem;

  }

}

@media (min-width: 1080px) {
  .upcCodesSectionWrapper {
   padding: 4rem 0;
  }

}

@media (min-width: 1440px) {}
@media (min-width: 1810px) {
  .upcCodesSectionWrapper {
    padding: 4rem 0;
   }
}
@media (min-width: 1920px) {}