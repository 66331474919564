
.templateWrapper{
  margin-top: 10rem;
  /* margin-top: 12rem; */
}
.categoryTextGroup{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.productSectionContainer{
  /* max-width: 180rem;
  margin: 0 auto; */
  background-color: var(--color-gray-06);
  border-top: 1px solid var(--color-gray-05);

  padding: 4rem 2rem;
}
.resultsCountBox{
  display: flex;
  gap: 1rem;
  flex-direction: row;
}
.textBox {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  height: inherit;
  padding-left: 2rem;
  justify-content: center;
  margin-top: 5rem;
  border-radius: 16px;
  /* line-height: normal; */
}

.titleText {
  font-size: var(--text-size-05);
  font-family: var(--slim-title-font-family);
  color: white;
  /* padding-bottom: 2rem; */
}

.buttonBox{
  margin-top: 2rem;
}
.buttonBox button{
  border: 1px solid black;
}

.categoryIntroductionContainer {
  margin: 3rem 0;
}


.compareText a {
  text-decoration: none;
  font-size: 2.5rem;
  color: white;
}

.categorySectionTitleText {
  font-size: 4rem;
  margin-bottom: 1rem;

  font-family: var(--title-font-family);
}

.sectionTitleContainer{

}
.descriptionText {
  font-size: 1.8rem;
  color: var(--color-gray-03);
}

.sectionTitle {
  font-size: var(--text-size-03);
  font-family: var(--slim-title-font-family);
  margin-bottom: 1rem;

  color: black;

}

.catCount {
  font-size: var(--text-size-02);
  color: var(--color-gray-03);
}
.catCountNum {
  font-size: var(--text-size-02);
  color: var(--color-red-02);
}

.productWrapper {
  background-color: var(--color-gray-06);
  border-radius: 16px;
}
/* .categorySectionWrapper div:not(:first-child){
  margin-top: 4rem;
} */
.buttonBox2{
  display: inline-block;
}
@media (min-width:514px) {
  .heroContainer {
    height: 50rem;
  }

  .categoryIntroductionContainer {
    margin: 5rem 0;
  }

  .textBox {
    margin-top: 10rem;
    padding-left: 4rem;
  }
  .titleText {
    font-size: 5rem;
  }

  .compareText a {
    font-size: 3rem;
  }

  .categorySectionTitleText {
    font-size: 4rem;
  }

  /* .sectionTitle {
    font-size: 3.5rem;
  } */

  .allCategoriesProductsContainer {
    padding-top: 1rem;
  }
}

@media (min-width:768px) {
    /* .categorySectionWrapper div:not(:first-child){
  margin-top: 4rem;
} */
  /* .categorySectionWrapper{
  padding: 0 4rem;
} */
  .heroContainer {
    height: 60vh;
  }

  .textBox {
    margin-top: 10rem;
    padding-left: 4rem;
  }

  .descriptionText {
    font-size: 2rem;
  }

  .categoryIntroductionContainer {
    margin: 5rem 15rem 5rem 0rem;
  }

  .productWrapper {
    margin-bottom: 20rem;
    /* padding-left: 4rem; */
  }
  /* .compareText {
    margin-top: 36rem;
  } */
}

@media (min-width: 1080px) {
  .templateWrapper{
    margin-top: 19rem;
    /* margin-top: 12rem; */
  }
  .productSectionContainer{
    padding: 4rem;
  }
  .gridContainer {
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 8em;
  }

  .heroContainer {
    height: 100vh;
    /* box-shadow: 25px 0 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45); */
  }

  /* .categoryIntroductionContainer {
    margin: 5rem 20rem 8rem 0rem;
  } */
  .categoryIntroductionContainer {
    margin: 5rem 50rem 5rem 0rem;
  }


 






  .productWrapper {
    margin-bottom: 10rem;
    padding-left: 4rem;
  }
  /* .compareText {
    margin-top: 42rem;
  } */
}

@media (min-width: 1440px) {
 
  .heroContainer {
    height: 70vh;

  }
  .textBox {
    margin-top: 15rem;
    padding-left: 4rem;
  }

  .titleText {
    font-size: 7rem;
  }

  .categorySectionTitleText {
    font-size: 6rem;
  }



  .descriptionText {
    font-size: 3rem;
  }
  .productWrapper{
    padding-left: 0;
    /* padding: 5rem 8rem; */
    /* margin: 0 4rem; */
  }
  .categoryIntroductionContainer {
    margin: 8rem 50rem 8rem 0rem;
  }
  .compareText {
    margin-top: 1rem;
  }

}

@media (min-width: 1920px) {
  /* .gridContainer {
    margin-left: 8rem;
    margin-right: 8rem;
  } */
  .categoryIntroductionContainer {
    margin: 8rem 100rem 8rem 0rem;
  }
  /* .compareText {
    margin-top: 40rem;

  } */

}