
.tableHeaderContainer{
    /* line-height: normal; */
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    border-bottom: 1px solid var(--color-gray-04);
}
.titleText{
    font-size: var(--text-size-05);
    font-family: var(--semiBold-title-font-family);
    color: black;
}
.subtitleText{
    font-size: var(--text-size-00);
    color: var(--color-gray-01);
    font-family: var(--slim-title-font-family);
}
.headlineText{
    font-size: var(--text-size-01);
    color: var(--color-gray-01);
    font-family: var(--slim-title-font-family);
}
@media (min-width: 514px) {}

@media (min-width: 768px) {}

@media (min-width: 1080px) {
    .tableHeaderContainer{
        margin-top: 2rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
    }
    .leaderRow {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
       
        padding: 1rem;
        align-items: center;
    }
}

@media (min-width: 1440px) {}

@media (min-width: 1920px) {}