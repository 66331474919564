.titleText {
    font-size: var(--text-size-02);
    /* font-family: var(--slim-title-font-family); */
    text-align: center;
}

.subtitleText {
    font-size: var(--text-size-02);
    font-family: var(--slim-title-font-family);
}

.mobileSearchHeader {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
}

.closeSearchIcon svg {
    display: inline-flex;
    height: 1.8rem;
    width: 1.8rem;
}


/* NAV SEARCH */
.desktopNavSearchComponent {
    display: none;
}

@media (min-width:1080px) {

    /* NAV SEARCH */
    .desktopNavSearchComponent {
        position: relative;
        width: 100rem;
        display: block;
        z-index: 1;
    }

    .desktopSearchPreviewWrapper {
        position: absolute;
        top: 90%;
    }
}