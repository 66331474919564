
.flashMessageContainerWrapper {
    position: fixed;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color-gray-06);
    color: #fff;
    padding: 5px;
    border-radius: 16px;
    border: 1px solid var(--color-gray-04);
    /* font-size: 18px;
    font-weight: bold; */
    z-index: 9999;
    width: max-content;
    /* background-color: aqua; */
}
.flashMessageContainerWrapper:hover{
    cursor: pointer;
}

.flashMessageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding:1rem;
}
/* .checkmark{
    color: green;
} */
/* .flashMessageContainer svg {
    font-size: var(--text-size-02);
} */


.messageText {
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-02);
}

@media (min-width:514px) {
    .flashMessageContainerWrapper {
        padding: 10px;
        border-radius: 16px;
        border: 1px solid var(--color-gray-04);
        font-size: 18px;
        font-weight: bold;
        z-index: 9999;
        width: max-content;
    }
    .flashMessageContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        padding:1rem 1.5rem;
    }
    
}