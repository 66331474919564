/* .desktopVideoComponent{
    display: none;
}
.mobileVideoComponent{
    display: block;
 
} */

.matchingProductContainer{    
    padding: 0;
}
 .matchingProductTitle{
    font-size: var(--text-size-02);
    color: var(--color-gray-02);
    font-family: var(--semiBold-title-font-family);
 }

.container {
    display: flex;
    
    overflow-x: auto;
    gap: 2rem;
    padding-bottom: 2rem;
}

.productCard{
    /* min-width: 200px;
    max-width: 300px; */
    flex: 0 0 0;
    /* flex-basis: 150px; 
  flex-grow: 1;     */
}
/* For WebKit-based browsers */
.container::-webkit-scrollbar {
    width: 12px;
}

.container::-webkit-scrollbar-track {
    background: var(--color-gray-05);
}

.container::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-04);
    border-radius: 10px;
    border: 3px solid var(--color-gray-05);
}

.container::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-red-02);
}

.scrollWrapper {
    display: flex;
    position: relative;

}

@media (min-width: 430px) {}
@media (min-width: 730px) {
    .matchingProductContainer{    
        padding: 0 2rem;
    }
}
@media (min-width: 1024px) {
    .videosScrollHeader {
        padding-top: 2rem;
    }
    .matchingProductContainer{    
        padding: 0 4rem;
    }
    .scrollWrapper {
        padding-bottom: 2rem;
    }
}

@media (min-width: 1735px) {
    .matchingProductContainer{    
        padding: 0;
    }
}