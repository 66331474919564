
.searchPreviewDropdown {
    background-color: var(--color-gray-07);
    /* box-shadow: var(--shadow-dark); */
    /* border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; */
    max-height: 60rem;
    width: auto;
    overflow-y: auto;
    overflow-x: hidden; 
    /* margin-top: 1rem; */
    padding-bottom: 2rem;
    /* margin-left: 5.7rem; */
    /* border-radius: 16px; */
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.searchItem {
    display: flex;
    align-items: center;
    padding: 1.5rem 2rem;
    column-gap: .5rem;
    width: 100%;
    /* border-bottom: 1px solid var(--color-gray-05); */
}
.previewTitleText {
    /* font-size: 1.7rem; */
    font-size: var(--text-size-01);
    font-family: var(--slim-title-font-family);
    color: black
}
.previewCategoryText {
    font-size: var(--text-size-00);
    font-family: var(--slim-title-font-family);
    color: var(--color-gray-03);
}
.previewSubtitleText {
    display: none;
    font-size: var(--text-size-01);
    font-family: var(--semiBold-title-font-family);
        color: black;

}
.searchPreviewImageBox {
    height: 8rem;
    align-self: baseline;
    /* background-color: white; */
    /* padding: 1.2rem; */
    border-radius: 14px;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
}

.searchPreviewImageBox img {
    height: 100%;
    width: 5rem;
    object-fit: contain;
}

.searchPreviewBtnBox {
    margin-left: auto;
}
.searchPreviewDropdown section:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-05);
}
@media (min-width:514px) {
    .searchPreviewImageBox {
        height: 12rem;
    }
    .previewSubtitleText {
        
        display: block;
    }
    .searchPreviewBtnBox {
        margin-left: auto;
    }
   
 
}
@media (min-width:768px) {
    .searchPreviewImageBox img:hover {
        cursor: pointer;
     }
     .searchPreviewTextBox:hover{
        cursor: pointer;
     }
}
@media (min-width:1080px) {
    .searchPreviewDropdown {
        width: 100rem;
    }
  
}
@media (min-width:1440px) {

.searchPreviewImageBox {
    height: 15rem;
    
}

.searchPreviewImageBox img {
    height: 100%;
    width: 9rem;
}

.searchItem {
    display: flex;
    align-items: center;
    padding: 2rem 4rem;
    column-gap: 1rem;
    width: 100%;
    /* border-bottom: 1px solid var(--color-gray-05); */
}
}
@media (min-width:1920px) {

}