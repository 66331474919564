.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0,0,0,0.75);
}

.modal {
    position: fixed;
    top: 30vh;
    left: 50%;
    width: 80%;
    transform: translateX(-50%); /* centered horizontally */
    z-index: 100;
    overflow: hidden;
    background: var(--color-gray-07);
    border-radius: 16px;
}

.header {
    background: var(--color-gray-05);
    padding: 1rem;
}

.header h2 {
    color: var(--color-gray-01);
    font-size: var(--alert-title-font-size);
}

.content {
    padding: 1rem;
}

.content p{
    color: var(--color-gray-02);
    font-size: var(--alert-body-font-size);
}

.actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}
.errorList{
    margin-top: .5rem;
}
.errorList li{
    font-size: var(--alert-body-font-size);
    color: var(--color-red-03);
    margin-left: 3rem;

}

@media (min-width: 768px) {
    .modal {
        max-width: 40rem;  /* fixed width */
    }
}

@media (min-width: 1440px) {}

@media (min-width: 1920px) {}
