
.noResultPreviewText {
    font-size: var(--text-size-01);
    font-family: var(--semiBold-title-font-family);
    color: var(--color-gray-01);
    
    padding: 2rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    background-color: var(--color-gray-07);
    /* margin-left: 5.7rem; */
}

@media (min-width:514px) {}

@media (min-width:768px) {
   
}

@media (min-width:1080px) {
    .noResultPreviewText{
         margin-top: .5rem;
    padding: 2rem;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    }
}

@media (min-width:1440px) {}

@media (min-width:1920px) {
    
}